.p-faq {
  margin-top: -250px;
  @include mq(md) {
    margin-top: 0;
  }
  &__inner {
    padding: 160px 115px 30px;
    margin-bottom: 195px;
    @include mq(md) {
      padding: 70px 27px 10px 27px;
      margin-bottom: 60px;
    }
  }

  &__faqSection {
    margin: 0 auto 105px;
    max-width: 1090px;
    @include mq(md) {
      margin: 0 0 60px;
    }
  }

  &__sectionTitle {
    font-size: 3rem;
    color: color(brand);
    @include mq(md) {
      font-size: 2rem;
    }
  }

  &__content {
    background-color: white;
    margin: 0;
    padding: 31px 0px 0;
    user-select: none;
    @include mq(md) {
      padding: 20px 0 0;
    }
  }

  &__question,
  &__answer {
    position: relative;
    font-size: 1.6rem;
    font-weight: bold;
    margin: 0;
    padding-left: 50px;
    line-height: 30px;
    font-family: $font-family-exception;
    word-break: break-all;

    @include mq(md) {
      font-size: 1.4rem;
      padding-left: 32px;
      line-height: 1.7;
    }

    &:before {
      position: absolute;
      display: inline-block;
      margin-right: 20px;
      left: 0px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      text-align: center;
      font-size: 1.4rem;
      line-height: 30px;

      @include mq(md) {
        width: 20px;
        height: 20px;
        font-size: 1rem;
      }
    }
  }

  &__question {
    &:before {
      content: 'Q';
      background-color: #757575;
      color: white;

      @include mq(md) {
        line-height: 2;
      }
    }
  }

  &__answer {
    color: color(brand);
    padding-top: 15px;
    padding-bottom: 30px;
    border-bottom: 1px solid #d9edef;

    &:before {
      content: 'A';
      background-color: color(brand);
      color: white;

      @include mq(md) {
        line-height: 2;
      }
    }
  }
}
