.p-header {
  // position: fixed;
  width: 100%;
  z-index: z(header);

  &__inner {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 40px 40px 40px 40px;
    height: 116px;
    @include mq(md) {
      padding: 27px 27px;
      height: 100px;
    }
  }

  &__logo {
    display: inline-block;
    padding-top: 8px;
    @include hover();

    @include mq(md) {
      padding-top: 0px;
    }
  }

  &__logoLink {
    display: block;

    > picture {
      display: block;

      @include mq(md) {
        width: 166px;
      }
    }
  }

  &__nav {
    position: fixed;
    right: 40px;
    display: flex;
    z-index: z(headerNav);
    @include mq(md) {
      right: 27px;
    }
  }

  &__entry {
    display: block;
    padding-top: 60px;
  }

  /* --------------------

  エントリーボタン

  -------------------- */

  &__entryBtn {
    box-sizing: initial;
    font-size: 1.8rem;
    font-weight: bold;
    border: 2px solid #000;
    border-radius: 3rem;
    padding: 0 34px;
    letter-spacing: 0.2rem;
    height: 52px;
    line-height: 52px;
    margin-right: 16px;
    transition: all 0.2s ease-out;
    background: #000;
    color: #fff;

    &.-hidden {
      display: none;
    }

    &:hover {
      background: #f3f7f9;
      color: #000;
    }
  }

  &__menuBtn {
    position: relative;
    background-color: #000;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: none;
    padding: 0;
    z-index: z(menuBtn);
    cursor: pointer;
    transition: all 0.2s ease-out;

    &.-show {
      background-color: #fff;
      position: fixed;
      right: 40px;
      @include mq(md) {
        right: 27px;
      }
    }

    @include mq(md) {
      width: 45px;
      height: 45px;
    }
  }

  &__bar {
    color: #fff;
    position: absolute;
    // width: 85px;
    // width: 100%;
    @at-root .ie & {
      width: 100%;
    }
    &.-show {
      &:before {
        color: #000;
        top: 50%;
        transform: rotate(45deg);
        transition: top 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
          transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0.15s;
      }

      &:after {
        color: #000;
        top: 50%;
        transform: rotate(-45deg);
        transition: top 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
          transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0.15s;
      }
    }

    &::before,
    &::after {
      content: '';
      top: 5px;
      position: absolute;
      left: 50%;
      margin-top: -1px;
      margin-left: -13px;
      display: block;
      height: 2px;
      width: 25px;
      border-radius: 2px;
      transform-origin: center center;
      background-color: currentColor;
      transition: top 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s,
        transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    }

    &::before {
      top: -5px;
    }
  }
}
