.c-people {
  &__inner {
    margin: 0 auto;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    width: calc(100% / 3);
    height: 270px;
    margin-bottom: 150px;

    &.-hidden {
      opacity: 0;
    }

    @include mq(md) {
      width: 50%;
      margin-bottom: 127px;
    }

    @include mq(1200px, max) {
      height: 240px;
    }
    @include mq(md) {
      height: 130px;
    }
  }

  &__link {
    display: block;
    height: inherit;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top;
    transition: all 0.5s;
    &:hover {
      opacity: 0.7;
    }

    &[data-people='01'] {
      background-image: url('/assets/media/img/common/people_nasu_small.jpg');
    }
    &[data-people='02'] {
      background-image: url('/assets/media/img/common/people_kuroki_small.jpg');
    }
    &[data-people='03'] {
      background-image: url('/assets/media/img/common/people_katakura_small.jpg');
    }
    &[data-people='04'] {
      background-image: url('/assets/media/img/common/people_iwasaki_small.jpg');
    }
    &[data-people='05'] {
      background-image: url('/assets/media/img/common/people_uchida_small.jpg');
    }
    &[data-people='06'] {
      background-image: url('/assets/media/img/common/people_yokoigawa_small.jpg');
    }
    &[data-people='07'] {
      background-image: url('/assets/media/img/common/people_tsushima_small.jpg');
      @include mq(md) {
        background-image: url('/assets/media/img/common/people_tsushima_small-sp.jpg');
      }
    }

    // &[data-type='interview'] {
    //   position: relative;
    //   &:before {
    //     content: '対談';
    //     font-size: 1.6rem;
    //     position: absolute;
    //     color: #fff;
    //     background: #0022ee;
    //     padding: 1px 9px;
    //     @include mq(md) {
    //       font-size: 1.4rem;
    //     }
    //   }
    // }
  }

  &__box {
    position: absolute;
    background: #fff;
    width: 82%;
    bottom: -50%;
    padding: 25px 30px;
    min-height: 126px;
    display: flex;
    align-items: center;
    min-height: 160px;

    &.-interview {
      min-height: initial;
      bottom: -30%;
      @include mq(1440px, min) {
        bottom: -40%;
      }
      @include mq(1200px, max) {
        bottom: -20%;
      }
      @include mq(md) {
        bottom: -60%;
      }
    }

    @include mq(1200px, max) {
      padding: 15px 18px;
      bottom: -40%;
    }
    @include mq(md) {
      bottom: -100%;
      width: 86%;
      padding: 11px 17px;
    }

    @include mq(sm) {
      bottom: -90%;
      width: 86%;
      padding: 11px 17px;
      min-height: 169px;
    }
  }

  /*-----------------------
  タイトル
  -----------------------*/

  &__info {
    min-height: 126px;

    &.-interview {
      min-height: initial;
    }
  }

  &__infoTitle {
    margin: 0;
    font-size: 1.8rem;
    margin-bottom: 12px;
    min-height: 64px;

    @include mq(1200px, max) {
      font-size: 1.6rem;
      min-height: 56px;
    }
    @include mq(md) {
      font-size: 1.4rem;
      line-height: 1.5;
      margin-bottom: 5px;
      letter-spacing: 0.1rem;
    }
  }

  &__infoMeta {
    font-size: 1.4rem;
    line-height: 1.8;
    letter-spacing: 0.01rem;
    font-family: $font-family-exception;

    &.-interview {
      min-height: initial;
    }

    @include mq(1300px, max) {
      min-height: 75px;
    }
    @include mq(1200px, max) {
      font-size: 1.3rem;
      min-height: 69px;
    }
    @include mq(md) {
      font-size: 1.2rem;
      line-height: 1.6;
    }
  }
}
