.p-navArea {
  background-color: #e8eaf1;

  &__bar {
    display: block;
    width: 100%;
    height: 10px;
    background: linear-gradient(
        90deg,
        #009b87 5%,
        #0024ee 50%,
        #0024ee 0%,
        #fe3876 95%
      )
      no-repeat 50% 50% / 100% 82%;
  }

  &__list {
    display: flex;
    justify-content: center;
  }

  &__listItem {
    width: calc(100% / 2);
    text-align: center;
    border-bottom: 1px solid #d7dae3;

    @include mq(md) {
      &:nth-of-type(1),
      &:nth-of-type(2) {
        width: calc(100% / 2);
      }

      // &:nth-of-type(3) {
      //   width: calc(100% / 2.7);
      // }
    }

    > a {
      position: relative;
      display: block;
      padding: 68px 0px;
      font-size: 2rem;
      font-weight: bold;
      @include mq(md) {
        font-size: 1.5rem;
        padding: 32px 0px;
      }
      @include mq(350px, max) {
        font-size: 1.3rem;
      }

      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 60px;
        background-color: #c2c5cf;
      }

      &:after {
        content: '';
        background-repeat: no-repeat;
        background-size: 100%;
        position: relative;
        display: inline-block;
        background-image: url('/assets/media/img/common/arw-nav.png');
        margin-left: 18px;
        top: 50%;
        transform: translateY(-50%);
        width: 15px;
        height: 8px;
        transition: transform 0.2s;
        @include mq(md) {
          width: 11px;
          height: 6px;
          margin-left: 9px;
        }
        @include mq(350px, max) {
          top: 1px;
        }
      }

      &:hover {
        // transition: all 0.4s;
        // box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2);

        &:after {
          transform: translate(6px, -50%);
        }
      }
    }

    &:first-of-type {
      > a::before {
        display: none;
      }
    }

    &:last-of-type {
      > a {
        border: none;
      }
    }
  }

  /*-----------------------
  Entryボタン
  -----------------------*/

  &__btnInner {
    padding: 113px 0;
    margin: 0 auto;
    @include mq(md) {
      padding: 61px 0;
    }
  }

  &__btnContainer {
    width: 280px;
    display: block;
    margin: 0 auto;
    @include mq(md) {
      width: 210px;
    }
  }
}
