.p-data {
  margin-top: -250px;
  @include mq(md) {
    margin-top: 0;
    padding: 60px 26px;
  }
  /* --------------------------------

  共通パーツ

  ---------------------------------*/
  &__inner {
    display: grid;
    max-width: 1250px;
    padding: 50px 20px 0;
    margin: 100px auto;
    grid-template-columns: 1fr 1fr 0.9fr 0.9fr 1fr 1fr;
    grid-template-rows: 340px 380px 500px 380px 360px 360px 380px 550px;
    grid-template-areas: "data01 data01 data01 data01 data01 data01"
                         "data02 data02 data02 data02 data02 data02"
                         "data03 data03 data04 data04 data04 data04"
                         "data05 data05 data05 data06 data06 data06"
                         "data07 data07 data07 data07 data09 data09"
                         "data08 data08 data08 data08 data09 data09"
                         "data10 data10 data10 data11 data11 data11"
                         "data12 data12 data12 data12 data12 data12";
    grid-gap: 10px;
    @include mq(md) {
      display: block;
      padding: 0 26px;
    }

    > section {
      overflow: hidden;
      @include mq(md) {
        margin-bottom: 10px;
      }

      &::before {
        content: '';
        background-image: url('/assets/media/img/common/triangle.svg');
        position: absolute;
        width: 20px;
        height: 20px;
        display: block;
      }
    }
  }

  &__text {
    font-size: 1.6rem;
    font-family: $font-family-exception;
    @include mq(md) {
      font-size: 1.5rem;
    }
  }

  &__picture {
    display: block;

    &.-show {
      animation: popPicture 0.25s ease-in-out;
    }
  }

  &__sectionTitle {
    font-size: 3rem;
    font-weight: bold;
    line-height: 1;
    font-family: $font-family-exception;
    @include mq(md) {
      font-size: 2.4rem;
    }
    @include mq(375px, max) {
      font-size: 2rem;
    }

    &.-small {
      font-size: 2.5rem;
      @include mq(lg2) {
        font-size: 2rem;
      }
      @include mq(lg) {
        font-size: 2rem;
      }
    }
  }

  &__subTitle {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    @include mq(sm) {
      font-size: 1.8rem;
    }
  }

  &__num {
    font-size: 6rem;
    font-weight: bold;
    color: color(brand);
    line-height: 1;
    margin: 0;
    white-space: nowrap;
    margin-bottom: 15px;
    @include mq(md) {
      font-size: 5.4rem;
    }
    @include mq(375px, max) {
      font-size: 4rem;
    }
    &.-medium {
      font-size: 4rem;
      line-height: 1;
      @include mq(lg2) {
        font-size: 2.2rem;
      }
      @include mq(lg) {
        font-size: 2.2rem;
      }
      > span {
        font-size: 6rem;
        line-height: 1;
        display: inline-block;
        height: auto;
        @include mq(md) {
          font-size: 4.8rem;
        }
        @include mq(375px, max) {
          font-size: 3.6rem;
        }
      }
    }

    &.-mediumLarge {
      font-size: 3.5rem;
      line-height: 1;
      @include mq(lg2) {
        font-size: 2.2rem;
      }
      @include mq(lg) {
        font-size: 2.2rem;
      }
      > span {
        font-size: 6rem;
        line-height: 1;
        display: inline-block;
        height: auto;
      }
    }

    &.-small {
      font-size: 3rem;
      line-height: 1;
      @include mq(md) {
        font-size: 2rem;
      }
      > span {
        font-size: 6rem;
        line-height: 1;
        display: inline-block;
        height: auto;
        @include mq(md) {
          font-size: 3rem;
        }
        @include mq(375px, max) {
          font-size: 3rem;
        }
      }
    }

    /* --------------------------------

  数字のスタイル

  ---------------------------------*/

    > span {
      font-size: 10rem;
      line-height: 1;
      display: inline-block;
      height: auto;
      text-align: right;
      @include mq(md) {
        font-size: 7rem;
      }
      @include mq(375px, max) {
        font-size: 6rem;
      }

      &.-hidden {
        visibility: hidden;
        opacity: 0;
      }

      &.-show {
        visibility: visible;
        opacity: 1;
      }
    }

    &.-dispatch {
      font-size: 3rem;
      margin-bottom: 20px;
      @include mq(md) {
        font-size: 2rem;
      }
      @include mq(375px, max) {
        font-size: 1.6rem;
      }
    }
  }

  /* --------------------------------

  セクション1

  ---------------------------------*/
  &__section1 {
    background: #fff;
    grid-area: data01;
    &__inner {
      display: flex;
      justify-content: space-between;
      padding: 6% 9% 0;
      // opacity: 0;
      @include mq(md) {
        position: relative;
        display: block;
        padding: 25px;
      }
    }

    &__wrapper1 {
      display: block;
      min-width: 180px;
    }

    &__wrapper2 {
      flex-basis: 440px;
      margin: 0 30px;
      @include mq(md) {
        width: 100%;
        margin: 0;
      }
    }

    &__wrapper3 {
      display: flex;
      align-items: center;
      flex-basis: 230px;
      @include mq(md) {
        position: absolute;
        width: 30%;
        max-width: 120px;
        top: 30px;
        margin-left: 50%;
      }
    }
  }

  /* --------------------------------

  セクション2

  ---------------------------------*/

  &__section2 {
    background: #fff;
    grid-area: data02;
    &__inner {
      display: flex;
      justify-content: space-between;
      padding: 5% 9% 0;
      // opacity: 0;
      @include mq(md) {
        position: relative;
        display: block;
        padding: 25px;
        height: 600px;
      }
      @include mq(450px, max) {
        height: 700px;
      }
      @include mq(350px, max) {
        height: 750px;
      }
    }
    &__wrapper1 {
      display: flex;
      align-items: center;
      flex-basis: 260px;
      min-width: 190px;
      @include mq(md) {
        display: block;
        width: 45%;
        min-width: initial;
        margin-bottom: 30px;
      }
    }
    &__wrapper2 {
      flex-basis: 730px;
      padding-left: 60px;
      @include mq(md) {
        padding-left: 0;
      }
    }

    &__list {
      display: flex;
    }

    &__item {
      width: calc(100% / 3);
      min-width: 200px;
      @include mq(md) {
        width: 40%;
        min-width: initial;
      }

      & + & {
        margin-left: 30px;
        @include mq(lg) {
          margin-left: 15px;
        }
        @include mq(md) {
          margin-left: 0;
        }
      }

      &:nth-of-type(1) {
        @include mq(md) {
          width: 45%;
        }
      }

      &:nth-of-type(2) {
        @include mq(md) {
          position: absolute;
          top: 0;
          right: 0;
          margin: 20px 25px 0 0;
        }
      }

      &:nth-of-type(3) {
        @include mq(md) {
          position: absolute;
          top: 300px;
          right: 0;
          margin: 5px 25px 0 0;
        }
        @include mq(450px, max) {
          top: 330px;
        }
      }
    }
  }

  /* --------------------------------

  セクション3

  ---------------------------------*/

  &__section3 {
    background: #fff;
    grid-area: data03;
    &__inner {
      padding: 10% 10% 0;
      min-width: 330px;
      // opacity: 0;
      @include mq(md) {
        position: relative;
        display: block;
        padding: 25px;
        min-width: initial;
      }

      > .p-data__sectionTitle {
        display: inline-block;
      }

      > .p-data__num {
        display: inline-block;
        position: relative;

        &[data-type='coron'] {
          margin: 0 5px;
          @include mq(lg2) {
            margin: 0;
          }
        }

        &[data-type='men'] {
          &:before {
            content: '男';
            position: absolute;
            font-size: 1.6rem;
            left: 30%;
            top: -10px;
          }
        }

        &[data-type='women'] {
          &:before {
            content: '女';
            position: absolute;
            font-size: 1.6rem;
            left: 30%;
            top: -10px;
          }
        }
      }
    }

    &__wrapper0 {
      @include mq(md) {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }
      > .p-data__picture {
        width: 210px;
        margin: 0 auto 40px;
        @include mq(md) {
          margin: 0;
          width: 35%;
          min-width: 80px;
        }
      }
    }

    &__wrapper1 {
      display: flex;

      @include mq(md) {
        display: block;
        flex-basis: 200px;
        padding-left: 20px;
        padding-top: 30px;
      }
      @include mq(375px, max) {
        padding-left: 15px;
      }

      > .p-data__sectionTitle {
        margin-right: 10px;
        display: flex;
        align-items: center;
      }
    }

    &__wrapper2 {
      display: flex;
    }
  }

  /* --------------------------------

  セクション4

  ---------------------------------*/

  &__section4 {
    background: #fff;
    grid-area: data04;

    &__inner {
      display: flex;
      justify-content: space-between;
      padding: 9% 9% 0;
      // opacity: 0;
      @include mq(md) {
        position: relative;
        display: block;
        padding: 25px;
        min-width: initial;
      }
    }

    &__wrapper1 {
      display: flex;
      align-items: center;
      flex-basis: 350px;
      min-width: 190px;
      @include mq(md) {
        width: 50%;
        min-width: initial;
        margin-left: auto;
      }
    }
    &__wrapper2 {
      flex-basis: 250px;
      margin-left: 50px;
      margin-top: 10px;
      @include mq(md) {
        margin-left: 0;
        width: 100%;
      }
    }

    &__wrapper2__heading {
      @include mq(md) {
        position: absolute;
        top: 15%;
        left: 5%;
      }
      @include mq(375px, max) {
        top: 12%;
      }

      > .p-data__num {
        &:first-of-type {
          margin-bottom: 10px;
        }

        @include mq(375px, max) {
          margin-bottom: 5px;
        }
      }
    }
  }

  /* --------------------------------

  セクション5

  ---------------------------------*/

  &__section5 {
    background: #fff;
    grid-area: data05;
    &__inner {
      display: flex;
      justify-content: space-between;
      padding: 8% 9% 0;
      // opacity: 0;
      @include mq(md) {
        position: relative;
        padding: 25px;
        height: 330px;
      }
      @include mq(425px, max) {
        height: 300px;
      }
    }
    &__wrapper1 {
      flex-basis: 230px;
      display: flex;
      align-items: center;
      @include mq(md) {
        width: 40%;
        max-width: 190px;
        padding-right: 20px;
        align-items: flex-start;
      }
      @include mq(425px, max) {
        max-width: 140px;
      }
      @include mq(sm) {
        padding-right: 10px;
        padding-top: 6%;
      }
    }
    &__wrapper2 {
      flex-basis: 220px;
      margin-left: 40px;
      @include mq(md) {
        margin-left: 0;
        width: 60%;
        padding-top: 10%;
      }

      > .p-data__sectionTitle {
        white-space: nowrap;
      }

      > .p-data__num {
        > span {
          font-size: 8rem;
          @include mq(md) {
            font-size: 5rem;
          }
        }
      }

      > .p-data__text {
        @include mq(md) {
          position: absolute;
          width: 100%;
          left: 0;
          padding: 0 25px;
          bottom: 10px;
        }
        @include mq(450px, min) {
          bottom: 20px;
        }
      }
    }
  }

  /* --------------------------------

  セクション6

  ---------------------------------*/

  &__section6 {
    background: #fff;
    grid-area: data06;
    &__inner {
      display: flex;
      justify-content: space-between;
      padding: 8% 9% 0;
      // opacity: 0;
      @include mq(md) {
        position: relative;
        padding: 25px;
        height: 330px;
      }
      @include mq(425px, max) {
        height: 300px;
      }
      @include mq(sm) {
        height: 320px;
      }
    }
    &__wrapper1 {
      flex-basis: 220px;
      margin-right: 40px;
      @include mq(md) {
        margin: 0 0 0 10%;
        width: 60%;
        padding-top: 10%;
      }
      @include mq(sm) {
        margin: 0 0 0 4%;
      }

      > .p-data__num {
        > span {
          font-size: 8rem;
          @include mq(md) {
            font-size: 5rem;
          }
        }
      }

      > .p-data__text {
        @include mq(md) {
          position: absolute;
          width: 100%;
          left: 0;
          padding: 0 25px;
          bottom: 10px;
        }
        @include mq(450px, min) {
          bottom: 20px;
        }
      }
    }
    &__wrapper2 {
      flex-basis: 220px;
      display: flex;
      align-items: center;
      @include mq(md) {
        width: 40%;
        max-width: 190px;
        align-items: flex-start;
      }
      @include mq(425px, max) {
        max-width: 140px;
      }
      @include mq(sm) {
        padding-right: 10px;
        padding-top: 6%;
      }
    }
  }

  /* --------------------------------

  セクション7

  ---------------------------------*/

  // &__section7 {
  //   background: #fff;
  //   grid-column: 1/7;
  //   grid-row: 5/6;

  //   &__inner {
  //     display: block;
  //     padding: 4% 4% 0;
  //     // opacity: 0;
  //     @include mq(md) {
  //       position: relative;
  //       padding: 25px;
  //     }
  //   }

  //   &__wrapper1 {
  //     display: flex;
  //     margin-bottom: 30px;
  //     @include mq(md) {
  //       display: block;
  //       margin-bottom: 15px;
  //     }

  //     > .p-data__sectionTitle {
  //       min-width: 125px;
  //       margin-right: 40px;
  //       @include mq(md) {
  //         margin: 0 0 10px 0;
  //       }
  //     }

  //     > .p-data__text {
  //       flex-basis: 945px;
  //     }
  //   }

  //   &__list {
  //     display: flex;
  //     justify-content: space-between;
  //     @include mq(md) {
  //       flex-wrap: wrap;
  //       justify-content: center;
  //     }
  //   }

  //   &__item {
  //     width: 20%;
  //     text-align: center;
  //     @include mq(md) {
  //       width: calc(100% / 3);
  //     }

  //     > .p-data__picture {
  //       width: 150px;
  //       margin: 0 auto 30px;
  //       @include mq(md) {
  //         width: 88%;
  //         margin: 0 auto 15px;
  //       }
  //     }
  //   }
  // }

  /* --------------------------------

  セクション8

  ---------------------------------*/

  &__section8 {
    background: #fff;
    grid-area: data07;

    &__inner {
      display: flex;
      justify-content: space-between;
      padding: 7% 9% 0;
      // opacity: 0;
      @include mq(md) {
        display: block;
        position: relative;
        padding: 25px;
      }
    }

    &__wrapper1 {
      flex-basis: 365px;
      margin-right: 40px;
      @include mq(md) {
        margin-right: 0;
      }
    }

    &__list {
      display: flex;
      justify-content: space-between;
      @include mq(md) {
        justify-content: center;
      }
    }

    &__item {
      text-align: center;
      @include mq(md) {
        width: 47%;
        max-width: 180px;
        margin: 0 10px;
      }
      > .p-data__picture {
        width: 150px;
        margin: 0 auto 30px;
        @include mq(md) {
          width: 100%;
          margin: 0 auto 15px;
        }
      }
    }

    &__wrapper2 {
      flex-basis: 210px;
      margin-top: 20px;
      @include mq(md) {
        margin-top: 10px;
      }

      > .p-data__sectionTitle {
        margin-bottom: 15px;
        @include mq(md) {
          margin-bottom: 5px;
        }
      }
    }
  }

  /* --------------------------------

  セクション9

  ---------------------------------*/

  &__section9 {
    background: #fff;
    grid-area: data08;

    &__inner {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      padding: 7% 9% 0;
      // opacity: 0;
      @include mq(md) {
        display: block;
        position: relative;
        padding: 25px;
      }
    }

    &__wrapper1 {
      flex-basis: 365px;
      margin-left: 40px;
      @include mq(md) {
        margin-left: 0;
      }
    }

    &__list {
      display: flex;
      justify-content: space-between;
      @include mq(md) {
        justify-content: center;
      }
    }

    &__item {
      text-align: center;
      @include mq(md) {
        width: 47%;
        max-width: 180px;
        margin: 0 10px;
      }
      > .p-data__picture {
        width: 150px;
        margin: 0 auto 30px;
        @include mq(md) {
          width: 100%;
          margin: 0 auto 15px;
        }
      }
    }

    &__wrapper2 {
      flex-basis: 210px;
      margin-top: 20px;
      @include mq(md) {
        margin-top: 10px;
      }

      > .p-data__sectionTitle {
        margin-bottom: 15px;
        @include mq(md) {
          margin-bottom: 5px;
        }
      }
    }
  }

  /* --------------------------------

  セクション10

  ---------------------------------*/

  &__section10 {
    background: #fff;
    grid-area: data09;

    &__inner {
      padding: 10% 10% 0;
      // opacity: 0;
      @include mq(md) {
        position: relative;
        padding: 25px;
      }

      > .p-data__picture {
        width: 100%;
        margin: 45px 0 40px;
        @include mq(md) {
          position: absolute;
          width: 45%;
          margin: 0 20px 0 auto;
          right: 0;
        }
        @include mq(375px, max) {
          max-width: 130px;
        }
        @include mq(sm) {
          margin: 0 10px 0 auto;
          width: 41%;
        }
      }

      > .p-data__sectionTitle {
        &:first-of-type {
          @include mq(md) {
            margin-bottom: 20px;
          }
        }
        @include mq(md) {
          margin-bottom: 10px;
        }
      }

      > .p-data__subTitle {
        font-size: 3.5rem;
        display: inline-block;
        margin-right: 10px;
        @include mq(lg2) {
          font-size: 3rem;
        }
        @include mq(md) {
          font-size: 2.4rem;
        }
        @include mq(sm) {
          font-size: 2rem;
        }
      }

      > .p-data__num {
        display: inline-block;
        margin-bottom: 10px;

        > span {
          line-height: 0.7;
        }
      }
    }

    &__item {
      > dl {
        font-size: 1.8rem;
        font-family: $font-family-exception;
        margin: 15px 0;
        line-height: 1;
        @include mq(md) {
          font-size: 1.6rem;
          margin: 10px 0;
        }
        @include mq(sm) {
          font-size: 1.5rem;
        }
        > dt {
          display: inline-block;
        }

        > dd {
          width: auto;
          display: inline-block;
          margin-left: 10px;
          @include mq(sm) {
            margin-left: 5px;
          }
        }
      }
    }
  }

  /* --------------------------------

  セクション11

  ---------------------------------*/

  &__section11 {
    background: #fff;
    grid-area: data10;

    &__inner {
      display: flex;
      justify-content: space-between;
      padding: 8% 9% 0;

      @include mq(md) {
        position: relative;
        padding: 25px;
      }
    }

    &__wrapper1 {
      flex-basis: 300px;
      margin-right: 0px;
      @include mq(md) {
        margin-right: 0;
        flex-basis: initial;
      }

      > .p-data__sectionTitle {
        &:first-of-type {
          margin-bottom: 25px;
        }
      }

      > .p-data__subTitle {
        font-size: 3.5rem;
        display: inline-block;
        margin-right: 15px;
        @include mq(lg2) {
          margin-right: 10px;
        }
        @include mq(md) {
          font-size: 3rem;
        }
        @include mq(sm) {
          font-size: 2.4rem;
          margin-right: 5px;
        }
      }

      > .p-data__num {
        display: inline-block;
        @include mq(lg2) {
          font-size: 3rem;
        }

        > span {
          font-size: 8rem;
          line-height: 0.4;
          @include mq(lg2) {
            font-size: 6rem;
          }
          @include mq(sm) {
            font-size: 4.6rem;
          }
        }
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
    }

    &__item {
      width: 46%;
      white-space: nowrap;
      @include mq(md) {
        width: 100px;
      }
      > dl {
        font-size: 1.8rem;
        font-family: $font-family-exception;
        margin: 7px 0;
        line-height: 1;
        @include mq(md) {
          font-size: 1.6rem;
        }
        > dt {
          display: inline-block;
        }

        > dd {
          display: inline-block;
          margin-left: 5px;
        }
      }
    }

    &__wrapper2 {
      flex-basis: 210px;
      margin-top: auto;
      @include mq(md) {
        position: absolute;
        width: 30%;
        right: 0;
        margin-right: 20px;
      }

      > .p-data__picture {
        @include mq(md) {
          min-width: 100px;
        }
        @include mq(sm) {
          min-width: 80px;
        }
      }
    }
  }

  /* --------------------------------

  セクション12

  ---------------------------------*/

  &__section12 {
    background: #fff;
    grid-area: data11;

    &__inner {
      display: flex;
      justify-content: space-between;
      padding: 8% 9% 0;
      // opacity: 0;
      @include mq(md) {
        position: relative;
        padding: 25px;
      }
      @include mq(sm) {
        padding: 25px 10px 25px 25px;
      }
    }

    &__wrapper1 {
      min-width: 200px;
      margin-right: 30px;
      @include mq(md) {
        min-width: initial;
        width: 60%;
        margin-right: 0;
      }
      @include mq(sm) {
        width: 90%;
      }

      > .p-data__sectionTitle {
        margin-bottom: 25px;
      }
    }

    &__item {
      > .p-data__subTitle {
        display: inline-block;
        font-size: 2.5rem;
        margin-right: 10px;
        @include mq(md) {
          font-size: 1.8rem;
          margin-right: 5px;
        }
      }

      > .p-data__num {
        display: inline-block;
        font-size: 3rem;
        @include mq(md) {
          font-size: 2.4rem;
          margin-bottom: 8px;
        }

        > span {
          font-size: 4.5rem;
          @include mq(md) {
            font-size: 3.2rem;
          }
        }
      }
    }

    &__wrapper2 {
      flex-basis: 230px;
      margin-top: auto;
      @include mq(md) {
        width: 50%;
        flex-basis: initial;
        margin: auto;
      }
    }
  }

  /* --------------------------------

  セクション13

  ---------------------------------*/

  &__section13 {
    background: #fff;
    grid-area: data12;

    &__inner {
      padding: 5% 6% 0;
      // opacity: 0;
      @include mq(md) {
        position: relative;
        padding: 25px;
      }
    }

    &__wrapper1 {
      display: flex;
      margin-bottom: 20px;
      @include mq(md) {
        display: block;
      }

      > .p-data__sectionTitle {
        flex-basis: 280px;
        margin-right: 40px;
        @include mq(md) {
          margin-bottom: 10px;
        }
      }

      > .p-data__text {
        flex-basis: 790px;
      }
    }

    &__wrapper2 {
      display: flex;
      justify-content: flex-start;
      @include mq(md) {
        flex-direction: row-reverse;
      }
    }

    &__wrapper2-1 {
      flex-basis: 330px;
      margin-right: 80px;
      display: flex;
      align-items: center;
      @include mq(md) {
        margin-right: 0;
        position: absolute;
        width: 30%;
      }
    }

    &__wrapper2-2 {
      min-width: 530px;
      margin-top: 20px;
      @include mq(md) {
        min-width: initial;
        margin-top: 0;
      }

      > .p-data__subTitle {
        font-size: 3.5rem;
        display: inline-block;
        margin-right: 10px;
        @include mq(lg2) {
          font-size: 3rem;
        }
        @include mq(md) {
          font-size: 2.2rem;
          margin: 10px 0;
        }
      }

      > .p-data__num {
        display: inline-block;
        margin-bottom: 10px;

        > span {
          line-height: 0.7;
        }
      }
    }

    &__item {
      > dl {
        font-size: 1.8rem;
        font-family: $font-family-exception;
        margin: 15px 0;
        line-height: 1;
        > dt {
          display: inline-block;
        }

        > dd {
          width: auto;
          display: inline-block;
          margin-left: 15px;
          @include mq(md) {
            margin-left: 5px;
            width: 60%;
          }
        }
      }

      &:nth-of-type(6) {
        > dl {
          > dt {
            position: relative;
            @include mq(md) {
              margin-left: 0px;
              top: 0px;
              vertical-align: top;
            }
          }
          > dd {
            @include mq(md) {
              width: 70%;
            }
          }
        }
      }
    }
  }
}

// @keyframes popPicture {
//   0% {
//     transform: scale(0.8);
//     opacity: 0;
//   }
//   80% {
//     transform: scale(1.05);
//     opacity: 1;
//   }
//   100% {
//     transform: scale(1);
//     opacity: 1;
//   }
// }
