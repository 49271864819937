.p-topMessage {
  position: relative;
  margin-top: -250px;
  @include mq(md) {
    margin-top: 0;
  }
  &__inner {
    padding: 0;
    background: #f3f7f9;
  }

  /*-----------------------
  メインエリア
  -----------------------*/

  &__content {
    display: flex;
    background-color: #fff;
    margin-left: 60px;
    padding: 107px 0 0 115px;
    @include mq(md) {
      display: block;
      margin-left: initial;
      padding: 0 27px;
    }

    &:nth-of-type(1) {
      padding-top: 160px;
      @include mq(md) {
        padding-top: 75px;
        padding-bottom: 40px;
      }
    }

    &:nth-of-type(2) {
      padding: 60px 0 0 115px;
      @include mq(md) {
        padding: 0 27px 29px;
      }
    }

    &:nth-of-type(3) {
      padding: 60px 0 120px 115px;
      @include mq(md) {
        padding: 0 27px 29px;
      }
    }
  }

  &__heading {
    max-width: 300px;
    padding-right: 60px;
    @include mq(md) {
      width: 100%;
      max-width: initial;
    }
  }

  &__title {
    min-width: 260px;
    max-width: 280px;
    font-size: 2.5rem;
    // padding-right: 80px;
    line-height: 1.5;
    @include mq(md) {
      max-width: initial;
      min-width: initial;
      font-size: 1.9rem;
      letter-spacing: 0.02rem;
      padding-right: initial;
    }
  }

  &__textContainer {
    @include mq(md) {
      margin-top: 19px;
    }
  }

  &__text {
    font-size: 1.6rem;
    line-height: 1.9;
    // margin-bottom: 90px;
    padding-right: 115px;
    font-family: $font-family-exception;
    max-width: 875px;
    @include mq(md) {
      font-size: 1.4rem;
      padding-right: initial;
      letter-spacing: 0.03rem;
      line-height: 1.7;
      margin-bottom: 20px;
    }
  }
  &__img {
    display: block;
    position: relative;
    right: 0;
    width: 100%;
    max-width: 980px;
    margin-right: auto;
    margin-top: 50px;
    @include mq(md) {
      width: initial;
      margin-right: calc(((100vw - 100%) / 2) * -1);
    }

    > img {
      width: 100%;
    }
  }

  /*-----------------------
  ナビゲーションエリア
  -----------------------*/
  &__navArea {
    background-color: color(base);
    padding: 160px 175px 165px;
    @include mq(md) {
      padding: 61px 23px 86px;
    }
  }
  &__navList {
    display: flex;
    justify-content: center;
  }
  &__navItem {
    width: 1090px;
    position: relative;
    background: #d8dee2;
    margin-right: 15px;
    @include mq(md) {
      width: 100%;
      max-width: initial;
      border: 5px solid #ebeff2;
    }

    &:last-of-type {
      margin-right: 0;
    }

    > a {
      display: block;
      color: #6f787f;
      font-size: 2.2rem;
      font-weight: bold;
      padding: 45px 50px;
      @include hover();
      @include mq(md) {
        font-size: 1.7rem;
        padding: 26px 27px;
      }

      &:after {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
        background-size: 100%;
        display: inline-block;
        background-image: url('/assets/media/img/common/arw-navbtn.svg');
        top: 50%;
        transform: translateY(-50%);
        right: 5%;
        width: 36px;
        height: 36px;
        transition: transform 0.2s;
        @include mq(md) {
          width: 28px;
          height: 28px;
          right: 8%;
        }
      }
    }
  }
}
