.c-grad-bg{
  background: linear-gradient(
      90deg,
      #0022ee 0%,
      #9900ff 8.33%,
      #ff3875 16.66%,
      #eb33ff 24.99%,
      #009b87 33.32%,
      #0022ee 41.65%,
      #9900ff 49.98%,
      #ff3875 58.31%,
      #eb33ff 66.64%,
      #009b87 74.97%,
      #0022ee 83.3%,
      #9900ff 91.63%,
      #ff3875 100%
    );
  background-repeat: no-repeat;
  background-position: 0% 0;
  background-size: 300% 100%;
  animation-duration: 12s;
  animation-name: grad-scroll;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes grad-scroll {
    0% {
      background-position: 62.475% 0;
    }
    100% {
      background-position: 0% 0;
    }
}
