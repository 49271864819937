.p-top {
  background: #fff;

  /*---------------------------------

  キービジュアル KV

  ---------------------------------*/
  &__kv {
    position: relative;
    height: calc(100vh - 156px);
    padding-top: 80px;
    background: #f3f7f9;
    overflow: hidden;
    width: 100%;
    // iPad
    // @media screen and (max-width: 1024px) and (orientation: portrait) {
    //   height: calc(100vh - 426px);
    // }
    @include mq(md) {
      height: calc(100vh - 100px);
      max-height: 560px;
      padding-top: 100px;
    }
    @include mq(380px, max) {
      padding-top: 80px;
      margin-bottom: 40px;
    }
  }

  &__kvCopy {
    position: absolute;
    z-index: z(topKvCopy);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4rem;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.7rem;
    opacity: 0;
    text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.38);
    white-space: nowrap;
    @include mq(md) {
      font-size: 2.6rem;
      width: max-content;
    }
  }

  &__kvWrapper {
    z-index: z(topKvWrapper);
    position: relative;
    width: calc(100% - 220px);
    // max-width: 1225px;
    // height: 560px;
    height: 60vh;
    margin: 0 auto;
    @include mq(md) {
      width: 100%;
      height: initial;
    }
  }

  &__kvTypo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: z(topKvNextInnovation);
    overflow: hidden;
    &.ie {
      opacity: 0;
    }
    > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &--animation {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-51%, -50%);
      width: 108%;
      max-width: 1100px;
      height: 350px;
    }
  }

  &__kvWrapperBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // height: 560px;
    height: 100%;
    opacity: 0;
    @include mq(md) {
      position: relative;
      height: 300px;
    }
    @include mq(380px, max) {
      height: 240px;
    }
    > span {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      background-image: url('/assets/media/img/common/top_kv_office.jpg');
      background-size: cover;
      background-position-y: center;
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      mix-blend-mode: screen;
      background: linear-gradient(
        90deg,
        #0022ee 0%,
        #9900ff 8.33%,
        #ff3875 16.66%,
        #eb33ff 24.99%,
        #009b87 33.32%,
        #0022ee 41.65%,
        #9900ff 49.98%,
        #ff3875 58.31%,
        #eb33ff 66.64%,
        #009b87 74.97%,
        #0022ee 83.3%,
        #9900ff 91.63%,
        #ff3875 100%
      );
      background-repeat: no-repeat;
      background-position: 0% 0;
      background-size: 300% 100%;
      animation-duration: 12s;
      animation-name: grad-scroll;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    } //&:after
    @media all and (-ms-high-contrast: none) {
      *::-ms-backdrop,
      > span {
        z-index: 1;
        opacity: 0.75;
      }
    }
    @supports (-ms-ime-align: auto) {
      > span {
        z-index: 1;
        opacity: 0.75;
      }
    }
  } //&__kvWrapperBg

  &__kvBg {
    z-index: z(topKvBg);
    position: relative;
    width: 100%;
    height: 745px;
    top: -280px;
    @include mq(md) {
      position: relative;
      top: initial;
      height: 400px;
    }
  }

  /*-----------------------
  人物
  -----------------------*/

  &__kvPersonList {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    display: flex;
    justify-content: space-around;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: z(topKvPersonList);
    @include mq(md) {
      top: -27%;
      transform: translate(-50%, 0);
    }
  }

  &__kvPerson {
    width: 200px;
    height: 280px;

    &[data-position='left'] {
      position: absolute;
      left: -6%;
      top: 50%;
      transform: translateY(-50%);

      // iPad
      @media screen and (max-width: 1024px) and (orientation: portrait) {
        left: -6vh;
      }
      @include mq(md) {
        display: none;
      }
    }

    &[data-position='center'] {
      position: relative;
      width: 60vh;
      min-width: 400px;
      height: 82vh;
      min-height: 640px;
      max-height: 720px;
      max-width: 520px;
      // iPad
      @media screen and (max-width: 1024px) and (orientation: portrait) {
        width: 40vh;
        height: 70vh;
      }
      @include mq(md) {
        width: 330px;
        height: 420px;
        min-width: initial;
        min-height: initial;
      }
      @include mq(380px, max) {
        width: 270px;
        height: 420px;
      }
      @include mq(sm) {
        width: 270px;
        height: 340px;
      }

      > picture {
        width: initial;
      }
    }

    &[data-position='right'] {
      position: absolute;
      right: -6%;
      top: 50%;
      transform: translateY(-50%);
      // iPad
      @media screen and (max-width: 1024px) and (orientation: portrait) {
        right: -6vh;
      }
      @include mq(md) {
        display: none;
      }
    }
  } //&__kvPerson

  &__kvPersonBase {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-image: url('/assets/media/img/common/top_person_bg.jpg');
    box-shadow: 0 6px 35px rgba(56, 54, 51, 0.25);
  } //&__kvPersonBase

  &__kvPersonImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &-container {
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      height: 100%;
      > span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: opacity 0.5s;
      }
    }
  } //&__kvPersonBase

  &__kvPersonInfo {
    position: absolute;
    bottom: 125px;
    right: -53px;
    display: inline-block;
    opacity: 0;
    @include mq(md) {
      right: 0%;
      bottom: 16%;
    }
  } //&__kvPersonInfo

  &__kvPersonYear,
  &__kvPersonPosition {
    font-size: 1.6rem;
    font-weight: bold;
    font-family: $font-family-exception;
    // color: #5523cd;
    line-height: 1.5;
    color: #fff;
    // animation-duration: 12s;
    // animation-name: kvPersonTextColor;
    // animation-timing-function: linear;
    // animation-iteration-count: infinite;
    text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.38);
    @include mq(md) {
      font-size: 1.1rem;
    }
  }

  &__kvPersonLink {
    position: relative;
    display: block;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.5;
    margin-top: 10px;
    padding-bottom: 10px;
    color: #fff;
    overflow: hidden;
    text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.38);
    &:hover {
      &:after {
        animation: hoverLink 0.5s ease-in-out;
      }
    }
    // animation-duration: 12s;
    // animation-name: kvPersonTextColor;
    // animation-timing-function: linear;
    // animation-iteration-count: infinite;
    @include mq(md) {
      margin-top: 5px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0px;
      display: block;
      height: 1px;
      width: 110%;
      background-color: #fff;
      // animation-duration: 12s;
      // animation-name: kvPersonBgColor;
      // animation-timing-function: linear;
      // animation-iteration-count: infinite;
      @include mq(md) {
        top: 28px;
        width: 115%;
      }
    }
  }

  /*-----------------------
  KVのUI
  -----------------------*/

  &__kvNav {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 280px;
    height: 40px;
    bottom: 13px;
    right: 40px;
    z-index: z(kvNav);
    @include mq(md) {
      bottom: -13px;
      right: 26px;
    }
  }

  &__kvNavBarContainer {
    display: flex;
  }

  &__kvNavCounter {
    position: relative;
    width: 17px;
    height: 40px;
    overflow: hidden;

    > span {
      display: inline-block;
      width: 100%;
      height: 100%;
      font-size: 1.4rem;
      line-height: 40px;
      color: #fff;
      text-align: right;
      &:last-child {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &:last-of-type {
      > span {
        opacity: 0.5;
      }
    }
  }

  &__kvNavBar {
    position: relative;
    height: 40px;
    width: 70px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 15px;
    overflow: hidden;
    @include mq(md) {
      width: 43px;
      margin: 0 8px;
    }
  }

  &__kvNavBarInner {
    position: absolute;
    background-color: #fff;
    height: 1px;
    width: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__kvNavBarBg {
    position: absolute;
    width: 100%;
    background-color: white;
    opacity: 0.3;
    height: 1px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__kvNavBtnContainer {
    display: flex;
    margin-left: 30px;
    @include mq(md) {
      margin-left: 16px;
    }
    &.disactive {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__kvNavNext,
  &__kvNavPrev {
    cursor: pointer;
    @include hover();
  }

  &__kvNavNext {
    margin-left: 5px;
  }

  &__kvNavPrevLink {
    > img {
      @include mq(md) {
        width: 40px;
      }
    }
  }

  &__kvNavNextLink {
    > img {
      transform: rotate(180deg);
      @include mq(md) {
        width: 40px;
      }
    }
  }

  &__kvScroll {
    display: inline-block;
    z-index: z(kvScroll);
    position: relative;
    left: 55px;
    @include mq(md) {
      left: 29px;
      top: 20px;
    }
  }

  &__kvScrollFigure {
    margin-bottom: 9px;
    cursor: pointer;

    > a {
      > img {
        @include mq(md) {
          width: 8px;
        }
      }
    }
  }

  &__kvScrollBar {
    position: relative;
    width: 11px;
    height: 60px;
    overflow: hidden;
    @include mq(md) {
      width: 8px;
      height: 38px;
    }
  }

  &__kvScrollBarInner {
    position: absolute;
    background-color: #fff;
    width: 1px;
    height: 30px;
    right: 50%;
    transform: translateX(-50%);
    animation: arrow 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite normal;
    @include mq(md) {
      height: 12px;
    }
  }

  &__kvScrollBarBg {
    position: absolute;
    width: 1px;
    height: 70px;
    background-color: white;
    opacity: 0.3;
    right: 50%;
    // transform: translateX(-50%);
  }

  &__kvFooter {
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: 0;
    @include mq(md) {
      bottom: 5%;
    }
  }

  &__kvFooterInner {
    position: relative;
    margin: 0 auto;
    bottom: 15%;
    @include mq(1150px, max) {
      bottom: 60px;
    }
    @include mq(md) {
      bottom: 0;
    }
  }

  /*---------------------------------

  ビジョン Vision

  ---------------------------------*/
  &__vision {
    position: relative;
    z-index: z(vision);
    padding-right: 60px;
    padding-top: 85px;
    margin-bottom: 360px;
    height: 350px;
    @include mq(md) {
      top: 0px;
      margin-right: 7%;
      margin-top: 0;
    }
    @include mq(380px, max) {
      top: 0px;
    }
  }

  &__visionInner {
    background-color: #f3f7f9;
    background-image: url('/assets/media/img/common/top_OurVision.png');
    background-repeat: no-repeat;
    background-size: 480px;
    background-position-y: bottom;
    display: flex;
    padding: 156px 15% 164px 21%;

    @include mq(md) {
      padding: 82px 27px;
      display: block;
      background-size: 267px;
    }
  }

  &__visionTitle {
    width: 44%;
    min-width: 310px;
    max-width: 450px;
    padding-right: 37px;
    margin-left: auto;
    transition: opacity 1.5s;
    &.-hidden {
      opacity: 0;
      visibility: hidden;
    }

    &.-show {
      opacity: 1;
      visibility: visible;
    }

    @include mq(md) {
      margin-left: 0;
      padding-right: 0;
      width: 100%;
      min-width: initial;
      max-width: initial;
      margin-bottom: 26px;
    }
  }

  &__visionBody {
    width: 600px;
    transition: opacity 1.5s;

    &.-hidden {
      opacity: 0;
      visibility: hidden;
    }

    &.-show {
      opacity: 1;
      visibility: visible;
    }

    @include mq(md) {
      width: initial;
    }
  }

  &__visionText {
    font-size: 1.8rem;
    line-height: 2;
    margin-bottom: 20px;
    font-family: $font-family-exception;
    @include mq(md) {
      font-size: 1.5rem;
      letter-spacing: 0.04rem;
      margin-bottom: 9px;
    }
  }
  &__visionBtn {
    margin-top: 40px;
    @include mq(md) {
      margin-top: 23px;
    }
  }

  /*---------------------------------

  事業紹介 Business

  ---------------------------------*/

  &__business {
    border-left: 10px solid color(brand);
    padding-right: 60px;
    position: relative;
    @include mq(md) {
      border-left: 6px solid color(brand);
      padding: 0 0 0 0;
    }
  }

  &__businessInner {
    position: relative;
    display: flex;
    padding: 170px 108px 170px 12%;
    max-width: 1440px;
    margin: 0 auto;
    @include mq(md) {
      display: block;
      padding: 94px 0 94px 22px;
    }
  }

  &__businessBody {
    padding-top: 50px;
    width: 47%;
    min-width: 370px;
    padding-right: 30px;

    transition: opacity 1.5s;

    &.-hidden {
      opacity: 0;
      visibility: hidden;
    }

    &.-show {
      opacity: 1;
      visibility: visible;
    }

    @include mq(md) {
      width: 100%;
      min-width: initial;
      padding: 0 7% 0 0;
    }
  }

  &__businessText {
    font-size: 1.8rem;
    line-height: 2;
    font-family: $font-family-exception;
    padding-left: 5px;
    letter-spacing: 0.03rem;
    margin-bottom: 45px;
    @include mq(md) {
      padding-left: 0;
      font-size: 1.5rem;
      margin-bottom: 23px;
    }
  }

  &__businessImg {
    position: relative;
    z-index: z(businessImg);
    min-width: 450px;
    height: 350px;

    @include mq(md) {
      position: relative;
      min-width: initial;
      height: auto;
      margin-top: 35px;
      padding: 0 7% 0 0;
    }

    > picture {
      display: block;
    }
  }

  &__businessBg {
    z-index: -100;
    display: block;
    position: absolute;
    width: 75%;
    height: 260px;
    right: -60px;
    top: 180px;
    @include mq(lg2) {
      height: 240px;
    }
    @include mq(lg) {
      height: 220px;
    }
    @include mq(md) {
      top: 90%;
      width: 65%;
      height: 65%;
      right: 0;
    }
  }

  &__businessBtn {
    @include mq(md) {
      text-align: right;
      margin-right: 3px;
    }
  }

  /*---------------------------------

  代表メッセージ Message

  ---------------------------------*/

  &__message {
    background: #f3f7f9;
    padding: 0 60px 0 60px;
    @include mq(md) {
      padding: 0;
    }
  }

  &__messageInner {
    position: relative;
    padding: 220px 0px 194px 0px;
    display: flex;
    justify-content: center;
    max-width: 1440px;
    margin: 0 auto;
    transform: translateX(-30px);
    @include mq(md) {
      display: block;
      padding: 80px 0px 87px;
      transform: initial;
    }
  }

  &__messageImg {
    z-index: z(messageImg);
    position: relative;
    @include mq(md) {
      width: 86%;
      margin-left: auto;
      top: 30px;
    }

    > picture {
      width: 100%;
      top: -50px;
      right: -60px;
      display: block;
      position: relative;
      @include mq(md) {
        right: 0px;
        top: 0;
        text-align: right;
      }
    }
  }

  &__messageBg {
    z-index: -100;
    position: absolute;
    top: 140px;
    width: 75%;
    height: 260px;
    @include mq(lg2) {
      height: 240px;
    }
    @include mq(lg) {
      height: 220px;
      top: 170px;
    }
    @include mq(md) {
      width: initial;
      top: 130px;
      margin-top: initial;
      width: 63%;
      left: -8%;
      height: 65%;
    }
  }

  &__messageBody {
    z-index: z(messageBody);
    width: 610px;
    min-width: 500px;
    height: 350px;
    background: #fff;
    @include mq(md) {
      position: relative;
      width: initial;
      min-width: initial;
      height: auto;
      margin-right: 7%;
    }
  }

  &__messageBodyInner {
    padding: 60px;
    transition: opacity 1.5s;

    &.-hidden {
      opacity: 0;
      visibility: hidden;
    }

    &.-show {
      opacity: 1;
      visibility: visible;
    }
    @include mq(md) {
      padding: 32px 28px 0;
    }
  }

  &__messageTitle {
    font-size: 3.5rem;
    font-weight: bold;
    letter-spacing: 0.03rem;
    line-height: 1.5;
    margin-bottom: 10px;
    @include mq(md) {
      font-size: 2.6rem;
      line-height: 1.6;
      margin-bottom: 14px;
    }
  }

  &__messageName {
    text-align: right;
    font-size: 1.6rem;
    font-weight: bold;
    @include mq(md) {
      font-size: 1.45rem;
    }
  }

  &__messageBtn {
    position: relative;
    transform: translateY(110%);
    @include mq(md) {
      transform: translateY(50%);
    }
  }

  /*---------------------------------

  社員紹介 People

  ---------------------------------*/

  &__people {
    background: #dde3e6;
  }

  &__peopleInner {
    padding: 170px 0 28px;
    display: flex;
    justify-content: flex-end;
    @include mq(md) {
      display: block;
      padding: 92px 0 0 28px;
    }
  }

  &__peopleTitle {
    margin-right: 50px;
    padding-left: 60px;
    min-width: 180px;
    @include mq(md) {
      margin-right: initial;
      padding: 0 0 33px 0;
      min-width: initial;
    }
  }

  &__peopleBody {
    width: 84%;
    @include mq(md) {
      width: 100%;
    }
  }

  &__peopleBtn {
    transform: translateY(100%);
    @include mq(md) {
      text-align: right;
      transform: translateY(50%);
      margin-right: 7%;
    }
  }

  /*---------------------------------

  ナビエリア

  ---------------------------------*/

  &__nav {
    background: #f3f7f9;
  }

  &__navInner {
    display: flex;
    justify-content: center;
    padding: 194px 0px 90px;
    max-width: 1440px;
    margin: 0 auto;
    @include mq(md) {
      display: block;
      margin: 0 7%;
      padding: 118px 0px 70px;
    }
  }

  &__navContainer {
    width: 44.5%;
    position: relative;
    @include mq(md) {
      width: 100%;
    }

    &:first-of-type {
      margin-right: 40px;
      @include mq(md) {
        margin-right: initial;
        margin-bottom: 25px;
      }
    }
  }

  &__navFigure {
    width: 100%;
    height: 360px;
    background-size: cover;
    pointer-events: none;

    @include mq(md) {
      height: 51vw;
    }

    &[data-type='data'] {
      background-image: url('/assets/media/img/common/top_nav__data.jpg');
    }

    &[data-type='movie'] {
      background-image: url('/assets/media/img/common/top_nav__movie.jpg');
    }

    &:hover {
      background-size: 120%;
    }
  }

  &__navBody {
    position: relative;
    width: 86%;
    background: #fff;
    padding: 60px 60px 80px 60px;
    top: -106px;
    @include mq(md) {
      padding: 32px 26px 43px;
      top: -54px;
      width: 93%;
    }
  }

  &__navText {
    font-size: 1.6rem;
    font-family: $font-family-exception;
    @include mq(md) {
      font-size: 1.5rem;
    }
  }

  &__navBtn {
    position: absolute;
    width: 160px;
    transform: translateY(55px);
    @include mq(md) {
      transform: translateY(23px);
      width: 150px;
    }
  }
}

/*---------------------------------

  アニメーション

  ---------------------------------*/

@keyframes arrow {
  0% {
    transform: translate3d(0%, 0px, 0);
  }

  40% {
    transform: translate3d(0%, 65px, 0);
  }

  100% {
    transform: translate3d(0%, 65px, 0);
  }
}

// @keyframes kvPersonTextColor {
//   0% {
//     color: #eb33ff;
//   }

//   20% {
//     color: #ff3875;
//   }

//   40% {
//     color: #9900ff;
//   }

//   60% {
//     color: #0022ee;
//   }

//   80% {
//     color: #009b87;
//   }

//   100% {
//     color: #eb33ff;
//   }
// }

// @keyframes kvPersonBgColor {
//   0% {
//     background-color: #eb33ff;
//   }

//   20% {
//     background-color: #ff3875;
//   }

//   40% {
//     background-color: #9900ff;
//   }

//   60% {
//     background-color: #0022ee;
//   }

//   80% {
//     background-color: #009b87;
//   }

//   100% {
//     background-color: #eb33ff;
//   }
// }

@keyframes hoverLink {
  0% {
    transform: translateX(0%);
  }
  49% {
    transform: translateX(100%);
    visibility: hidden;
  }
  50% {
    transform: translateX(-100%);
    visibility: hidden;
  }
  52% {
    visibility: visible;
  }
  100% {
    transform: translateX(0%);
  }
}
