.l-contents {
  margin-right: auto;
  margin-left: auto;

  @include mq(md) {
    width: 100%;
    min-width: initial;
  }

  @include mq(md, min) {
    max-width: 1250px;
    width: 100%;
    min-width: 1040px;
  }
}
