.l-main {
  background-color: #fff;
  margin-left: 60px;
  margin-right: auto;
  padding-left: 115px;

  @include mq(md) {
    margin-left: 0;
  }
}
