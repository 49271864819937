// プロジェクト全体のベース
// ==========================================================================

html {
  box-sizing: border-box;
  font-family: $font-family-default;
  font-feature-settings: 'palt';
  -webkit-text-size-adjust: 100%; // iOS 8+
  font-size: 62.5%;
  color: #000;
  background-color: color(base);
}

*,
::before,
::after {
  box-sizing: inherit;
}

img,
video {
  max-width: 100%;
  vertical-align: middle;
}

figure {
  margin: 0;
}

svg {
  fill: currentColor;
}

body {
  line-height: 1.8;
  min-width: 1040px;
  margin: 0;

  @include mq(md) {
    width: 100%;
    min-width: auto;
  }

  &.-hidden {
    overflow: hidden;
  }
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
}

h2 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none;
  color: #000;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.only-sp {
  display: none;

  @include mq(md) {
    display: inherit;
  }
}

.only-pc {
  display: inherit;

  @include mq(md) {
    display: none;
  }
}

.body_wrap {
  background-color: color(base1);
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}
