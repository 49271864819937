.p-peopleDetail {
  margin-top: -250px;
  @include mq(md) {
    margin-top: 0;
  }
  &__inner {
    @include mq(md) {
      padding: 0 27px;
    }
  }

  /*-----------------------
  対談
  -----------------------*/

  &__interviewIntro {
    width: 880px;
    padding: 80px 0 0;
    margin: 0 auto;
    font-size: 1.8rem;
    font-family: $font-family-exception;
    text-align: left;
    @include mq(md) {
      width: 100%;
      font-size: 1.5rem;
      padding: 0 0 60px;
    }
  }
  /*-----------------------
  インタビュー
  -----------------------*/
  &__interview {
    padding: 40px 115px 140px;
    border-bottom: 1px solid #cedbe1;
    &[data-type='interview'] {
      padding-bottom: 100px;
      @include mq(md) {
        padding-bottom: 20px;
      }
    }

    @include mq(md) {
      padding: 77px 27px 13px 27px;
      margin-right: calc(((100vw - 100%) / 2) * -1);
      margin-left: calc(((100vw - 100%) / 2) * -1);
    }
  }

  &__wrapper {
    // transition: transform 2.5s, opacity 2s;
    // transform: translate(0, 90px);
    &.-hidden {
      visibility: hidden;
      opacity: 0;
    }

    &.-show {
      visibility: visible;
      opacity: 1;
      transform: translate(0, 0);
    }
  }
  &__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 100px 0 0 0;
    max-width: 1210px;
    margin: 0 auto;
    // transition: transform 2.5s, opacity 2s;
    // transform: translate(0, 90px);

    &[data-type='interview'] {
      padding-bottom: 80px;
      &:last-of-type {
        padding-top: 0;
      }
      @include mq(md) {
        padding: 0 0 60px;
      }
    }

    &.-reverse {
      flex-direction: row-reverse;
      padding-top: 0;
      transform: translate(0, 0px);
    }

    &.-hidden {
      visibility: hidden;
      opacity: 0;
    }

    &.-show {
      visibility: visible;
      opacity: 1;
      transform: translate(0, 0);
    }

    @include mq(md) {
      display: block;
      padding: 0 0 40px;
    }

    &.-middle {
      @include mq(md) {
        padding-bottom: 40px;
      }
    }
  }

  &__textContainer {
    width: 50%;
    padding-right: 80px;
    &[data-type='interview'] {
      width: 100%;
      max-width: 1210px;
      margin: 0 auto;
      padding-right: 0;
      @include mq(md) {
        padding-top: 0;
      }
    }
    @include mq(md) {
      width: 100%;
      padding-right: 0;
    }

    &.-middle {
      width: 100%;
      max-width: 980px;
      margin: 0 auto;
      padding-right: 0;
    }

    &.-reverse {
      padding-right: 0;
      padding-left: 80px;
      @include mq(md) {
        padding-left: 0;
      }
    }
  }

  &__imgContainer {
    width: 50%;
    text-align: center;

    &[data-type='interview'] {
      padding-top: 75px;
      @include mq(md) {
        padding-top: 0;
      }
    }
    @include mq(md) {
      width: 100%;
      margin-top: 22px;
      margin-bottom: 22px;
    }
  }

  &__img {
    display: block;
    &[data-type='interview'] {
      width: 40%;
      float: right;
      margin: 0 0 40px 60px;
      @include mq(md) {
        width: 100%;
        float: initial;
        margin: 0 0 20px;
      }
    }
    &[data-type='interviewReverse'] {
      width: 40%;
      float: right;
      margin: 0 0 40px 60px;
      @include mq(md) {
        width: 100%;
        float: initial;
        margin: 0;
        margin: 0 0 20px;
      }
    }
  }

  /*-----------------------
  質問
  -----------------------*/

  &__question {
    display: flex;
    position: relative;
    font-size: 2rem;
    font-family: $font-family-exception;
    font-weight: bold;
    color: #707a86;
    line-height: 1.5;
    // margin-bottom: 30px;
    padding-left: 43px;
    @include mq(md) {
      font-size: 1.6rem;
      padding-left: 24px;
      letter-spacing: 0.06rem;
      margin-bottom: 11px;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 35px;
      height: 2px;
      background: #707985;
      top: 22px;
      left: 0;
      @include mq(md) {
        width: 20px;
        height: 1px;
        top: 19px;
      }
    }
  }

  /*-----------------------
  回答
  -----------------------*/

  &__answerTitle {
    font-size: 3rem;
    line-height: 1.5;
    max-width: 1210px;
    margin: 0 auto 30px;
    @include mq(md) {
      font-size: 2.2rem;
      margin-bottom: 20px;
    }
  }

  &__answerText {
    display: flex;
    font-size: 1.6rem;
    line-height: 1.9;
    letter-spacing: 0.02rem;
    font-family: $font-family-exception;
    margin: 15px 0;
    &:before {
      font-weight: bold;
      display: block;
      width: 56%;
      min-width: 48px;
      max-width: 48px;
      @include mq(md) {
        min-width: 48px;
        max-width: initial;
        width: initial;
      }
    }
    &[data-people='鈴木'] {
      &:before {
        content: '鈴木';
      }
    }
    &[data-people='千葉'] {
      &:before {
        content: '千葉';
      }
    }
    &[data-people='対馬'] {
      &:before {
        content: '対馬';
      }
    }
    @include mq(md) {
      font-size: 1.4rem;
      letter-spacing: 0.05rem;
      line-height: 1.6;
    }
  }

  /*-----------------------
  words Question
  -----------------------*/

  &__wordsInner {
    padding: 82px 50px 150px;
    @include mq(md) {
      padding: 40px 0 55px;
    }
  }

  &__wordsTitle {
    margin: 0 auto 20px;
    font-size: 3rem;
    text-align: center;
    letter-spacing: 0.05rem;
    color: color(brand);
    @include mq(md) {
      font-size: 2.2rem;
      margin: 0 auto 20px;
    }

    > span {
      display: inline;
      background: linear-gradient(transparent 84%, #ffff 0%);
      background-position-y: -4px;
      line-height: 1;
    }
  }

  &__wordsintrotext {
    font-size: 1.6rem;
    text-align: center;
    font-family: $font-family-exception;
    @include mq(md) {
      font-size: 1.4rem;
      line-height: 1.6;
    }
  }

  &__wordsList {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 30px auto 0;
    @include mq(md) {
      display: block;
    }
  }

  &__wordsItem {
    background-color: #fff;
    width: 25%;
    min-width: 280px;
    max-width: 350px;
    padding: 15px 30px 28px;
    margin: 0 10px 20px;

    @include mq(md) {
      width: 100%;
      margin-right: 0;
      min-width: initial;
      max-width: initial;
      margin: 0 0 11px;
      padding: 15px 22px 20px;
    }
  }

  &__wordsItemTitle {
    text-align: center;
    font-size: 1.8rem;
    min-height: 64px;
    margin-bottom: 5px;
    font-family: $font-family-exception;
    @include mq(md) {
      text-align: left;
      font-size: 1.6rem;
      margin: 0 0 3px 0;
      letter-spacing: -0.03rem;
      min-height: initial;
    }
  }

  &__wordsText {
    font-size: 1.6rem;
    font-family: $font-family-exception;
    @include mq(md) {
      font-size: 1.4rem;
      line-height: 1.6;
    }
  }

  /*-----------------------
  その他人物
  -----------------------*/

  &__others {
    background: #dde3e6;
    @include mq(md) {
      padding: 54px 27px 42px;
      margin-right: calc(((100vw - 100%) / 2) * -1);
      margin-left: calc(((100vw - 100%) / 2) * -1);
    }
  }

  &__othersInner {
    padding: 105px 115px 128px;
    @include mq(md) {
      padding: 0;
      margin-right: calc(((100vw - 100%) / 2) * -1);
    }
  }

  &__othersTitle {
    font-size: 3rem;
    letter-spacing: 0.05rem;
    margin-bottom: 34px;
    @include mq(md) {
      font-size: 2.2rem;
      margin-bottom: 16px;
    }
  }
}
