.p-scroll {
  position: absolute;
  display: inline-block;
  z-index: z(commonScroll);
  right: 54px;
  bottom: -40px;

  @include mq(md) {
    right: 27px;
    bottom: -96px;
  }

  &__figure {
    width: 11px;
    cursor: pointer;
    @include hover();
    @include mq(md) {
      width: 8px;
    }

    > a {
      display: block;

      > picture {
        display: block;
        margin-bottom: 7px;
      }
    }
  }

  &__bar {
    position: relative;
    width: 11px;
    height: 60px;

    overflow: hidden;
    @include mq(md) {
      width: 8px;
      height: 38px;
    }
  }

  &__barInner {
    position: absolute;
    background-color: #fff;
    width: 1px;
    height: 30px;
    right: 50%;
    transform: translateX(-50%);
    animation: arrow 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite normal;
    @include mq(md) {
      height: 12px;
    }
  }

  &__barBg {
    position: absolute;
    width: 1px;
    height: 70px;
    background-color: white;
    opacity: 0.3;
    right: 50%;
    // transform: translateX(-50%);
    @include mq(md) {
      height: 38px;
    }
  }
}
