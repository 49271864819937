.c-btn {
  /*-----------------------
  エントリーボタン
  -----------------------*/
  &.-entry {
    position: relative;
    display: block;
    background-color: #000;
    color: #fff;
    font-size: 2.5rem;
    line-height: 1;
    padding: 21px 70px;
    border-radius: 5rem;
    letter-spacing: 0.5rem;
    text-align: right;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2);
    @include mq(md) {
      font-size: 2rem;
      padding: 18px 50px 18px 54px;
    }

    &:after {
      content: '';
      background-repeat: no-repeat;
      background-size: 100%;
      position: absolute;
      display: inline-block;
      background-image: url('/assets/media/img/common/icon-entry.svg');
      top: 50%;
      transform: translateY(-50%);
      left: 70px;
      width: 24px;
      height: 24px;
      @include mq(md) {
        top: 52%;
        width: 17px;
        height: 17px;
        left: 45px;
      }
    }
  }

  /*-----------------------
  エントリーボタン（MENU内）
  -----------------------*/

  &.-menuEntry {
    position: relative;
    display: block;
    background-color: #000;
    border: 2px solid #fff;
    color: #fff;
    font-size: 2.5rem;
    line-height: 1;
    padding: 21px 77px 21px 107px;
    border-radius: 5rem;
    text-align: center;
    letter-spacing: 0.5rem;
    @include mq(md) {
      font-size: 2rem;
      padding: 18px 40px 18px 54px;
    }

    &:after {
      content: '';
      background-repeat: no-repeat;
      background-size: 100%;
      position: absolute;
      display: inline-block;
      background-image: url('/assets/media/img/common/icon-entry.svg');
      top: 50%;
      transform: translateY(-50%);
      left: 70px;
      width: 24px;
      height: 24px;
      @include mq(md) {
        top: 52%;
        width: 17px;
        height: 17px;
        left: 45px;
      }
    }
  }

  /*-----------------------
  Moreボタン
  -----------------------*/
  &.-more {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    max-width: 160px;
    padding: 17px 28px;
    font-size: 1.6rem;
    line-height: 1;
    color: #fff;
    text-align: left;
    letter-spacing: 0.12em;
    border-radius: 3em;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2);

    @include mq(md) {
      max-width: 138px;
      font-size: 1.4rem;
      padding: 14px 21px;
    }

    &:hover {
      &:after {
        transform: translate(6px, -50%);
        background-image: url('/assets/media/img/common/arw-nav.png');
      }
    }

    &:after {
      content: '';
      position: absolute;
      background-repeat: no-repeat;
      background-size: 100%;
      display: inline-block;
      background-image: url('/assets/media/img/common/arw-btn.png');
      top: 50%;
      transform: translateY(-50%);
      right: 26px;
      width: 15px;
      height: 8px;
      transition: all 0.2s ease-out;
      transition-delay: 0.15s;
      @include mq(md) {
        right: 20px;
      }
    }
  }

  /*---------------------------------

  演出系

  ---------------------------------*/

  &.-light {
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      width: 200%;
      height: 200%;
      background: rgba(255, 255, 255, 0.2);
      transform: rotate(-45deg);
      position: absolute;
      top: -10%;
      left: -180%;
      transition: 0.3s ease-in-out;
    }

    &:hover {
      &:before {
        left: 60%;
      }
    }
  }

  &.-arw {
    background-color: #000;
    color: #fff;
    background-size: 200% 100%;
    background-image: linear-gradient(
      to right,
      transparent 50%,
      rgb(255, 255, 255) 50%
    );
    transition: background-position 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s,
      color 0.5s ease 0s, background-color 0.5s ease;

    &:hover {
      color: #000;
      background-color: #fff;
      background-position: -100% 100%;
    }
  }

  &.-inactive {
    pointer-events: none;
    background: #bbb;
  }
}
