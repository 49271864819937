.c-sectionTitle {
  font-size: 3rem;
  line-height: 1;
  color: color(brand);
  @include mq(md) {
    font-size: 2rem;
    letter-spacing: 0.2rem;
  }

  &.-vision {
    letter-spacing: 0.2rem;
    @include mq(md) {
      font-size: 2.2rem;
    }
  }

  &.-business {
    letter-spacing: 0.2rem;
    margin-bottom: 37px;
    @include mq(md) {
      margin-bottom: 25px;
    }
  }

  &.-message {
    margin-bottom: 26px;
  }

  &.-nav {
    margin-bottom: 31px;
    @include mq(md) {
      margin-bottom: 21px;
    }
  }

  // &:after {
  //   content: '';
  //   display: block;
  //   width: 40px;
  //   height: 3px;
  //   margin-top: 15px;
  //   background: linear-gradient(
  //       90deg,
  //       #009b87 5%,
  //       #0024ee 50%,
  //       #0024ee 0%,
  //       #fe3876 95%
  //     )
  //     no-repeat 50% 50% / 100% 82%;
  //   @include mq(md) {
  //     width: 28px;
  //     height: 3px;
  //     margin-top: 9px;
  //   }
  // }

  &-sub {
    display: block;
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 7px;
    font-family: $font-family-serif;
    color: #757575;
    @include mq(md) {
      font-size: 1rem;
    }

    &.-vision {
      @include mq(md) {
        margin-bottom: 3px;
      }
    }
  }
}
