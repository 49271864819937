// 変数
// ==========================================================================

// Breakpoint（_mixins.scssを参照）
// --------------------------------------------------------------------------
$screen: (
  sm: 320px,
  md: 768px,
  lg: 1040px,
  lg2: 1210px,
  lg3: 1320px
) !default;

// color（_functions.scssを参照、3回以上使う色は変数化する）
// --------------------------------------------------------------------------
$color: (
  brand: #009b87,
  base: #f3f7f9,
  main: #1f2833
);

// z-index（_functions.scssを参照）
// --------------------------------------------------------------------------
$z-map: (
  content: true,
  topKvBg: true,
  breadcrumb: true,
  vision: true,
  topKvWrapper: true,
  topKvNextInnovation: true,
  topKvPersonList: true,
  topKvCopy: true,
  pageHeader_bg: true,
  kvNav: true,
  pageHeader_kv: true,
  pageHeader_title: true,
  kvScroll: true,
  commonScroll: true,
  aboutImgBg: true,
  aboutImg: true,
  businessImg: true,
  messageImg: true,
  messageBody: true,
  menu: true,
  menuBtn: true,
  header: true,
  headerNav: true
);

// Font-family
// --------------------------------------------------------------------------
$font-family-default: 'sofia-pro', '游ゴシック体', YuGothic, sans-serif;
$font-family-exception: '游ゴシック体', YuGothic, sans-serif;
$font-family-serif: 'LTC-Caslon-Pro', serif;

// unit
// --------------------------------------------------------------------------
// viewport size(for smartphone)
// デバイス幅 750px のデザインカンプ上で何pxかを表す
// 下記の例はカンプ上の60px
// ```scss
// .sample {
//   width: 60 * $vw;
// }
// ```
$vw: 100vw / 750;
