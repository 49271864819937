.p-employment {
  margin-top: -250px;
  margin-bottom: 204px;
  @include mq(md) {
    margin-top: 0;
  }
  @include mq(md) {
    margin-bottom: 0;
  }

  &__inner {
    padding: 169px 115px 136px 115px;
    @include mq(md) {
      padding: 83px 27px 105px;
    }
  }

  &__wrapper {
    max-width: 1150px;
    margin: 0 auto;
  }

  /*-----------------------
  ナビゲーション
  -----------------------*/
  &__navList {
    display: flex;
    justify-content: flex-end;
    @include mq(md) {
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }

  &__navItem {
    display: block;
    margin-right: 47px;
    @include mq(md) {
      width: 36%;
      min-width: 115px;
      max-width: 135px;
      margin-right: initial;
      margin-bottom: 24px;
    }
    @include mq(sm) {
      width: 50%;
    }

    &:last-of-type {
      margin-right: 30px;
      @include mq(md) {
        margin-right: 0;
      }
    }
  }

  &__navLink {
    position: relative;
    display: inline-block;
    font-size: 1.8rem;
    font-weight: bold;
    border-right: 1px solid #bbbbbb;
    padding-right: 9px;
    line-height: 1.6rem;
    letter-spacing: 0.05rem;
    @include hover();

    &:after {
      content: '';
      background-repeat: no-repeat;
      background-size: 100%;
      position: absolute;
      display: inline-block;
      background-image: url('/assets/media/img/common/arw-employment.svg');
      top: 50%;
      transform: translate(20px, -50%);
      width: 10px;
      height: 6px;
      transition: transform 0.2s;
      @include mq(md) {
        transform: translate(20px, -50%);
      }
    }

    &:hover {
      &:after {
        transform: translate(20px, -20%);
        @include mq(md) {
          transform: translate(20px, -20%);
        }
      }
    }
  }

  /*-----------------------
  コンテンツ
  -----------------------*/

  &__container {
    padding-top: 60px;
    margin-bottom: 60px;
    @include mq(md) {
      margin-bottom: 30px;
      padding-top: 35px;
    }

    &:first-of-type {
      padding-top: 56px;
      @include mq(md) {
        padding-top: 20px;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__containerTitle {
    font-size: 3rem;
    color: color(brand);
    line-height: 1;
    margin-bottom: 28px;
    @include mq(md) {
      font-size: 2.2rem;
      margin-bottom: 14px;
    }

    &.-welfare {
      margin-bottom: 4px;
      @include mq(md) {
        margin-bottom: 15px;
      }
    }

    &.-training {
      margin-bottom: 35px;
      @include mq(md) {
        margin-bottom: 17px;
      }
    }

    &.-flow {
      margin-bottom: 10px;
      @include mq(md) {
        margin-bottom: 7px;
      }
    }
  }

  /*-----------------------
  募集要項
  -----------------------*/
  &__requirement {
    margin-bottom: 15px;
    @include mq(md) {
      margin-bottom: 13px;
    }
  }

  &__requirementTitle {
    position: relative;
    background: #f2f2f2;
    font-size: 2rem;
    margin: 0;
    padding: 17px 27px;
    cursor: pointer;
    transition: all 0.3s;
    @include mq(md) {
      font-size: 1.6rem;
      padding: 10px 17px;
    }

    &:hover {
      background: #f6f5f6;
    }

    &:after {
      content: '';
      background-size: 100%;
      position: absolute;
      display: inline-block;
      background-image: url('/assets/media/img/common/arw-requirement.svg');
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      width: 36px;
      height: 36px;
      transition: transform 0.3s;
      @include mq(md) {
        width: 26px;
        height: 26px;
        right: 12px;
      }
    }

    &[aria-expanded='true'] {
      background: #f6f5f6;

      &:after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &__requirementContent {
    display: none;
  }

  &__requirementInner {
    border: 6px solid #f6f5f6;
    border-top: none;
    padding: 38px 109px 76px;
    @include mq(md) {
      padding: 0px 16px 25px;
    }
  }

  &__table {
    font-family: $font-family-exception;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    // &.-welfare {
    //   display: block;
    // }
  }

  &__tableRow {
    &.-welfare {
      @include mq(md) {
        display: block;
      }
    }
  }

  &__tableKey {
    font-size: 1.6rem;
    color: #666666;
    text-align: left;
    border-bottom: 1px solid #999999;
    width: 19%;
    padding: 25px 0 10px;
    vertical-align: top;
    @include mq(md) {
      font-size: 1.4rem;
      padding: 13px 1px 5px;
      width: 23%;
    }

    &.-welfare {
      width: 20%;
      @include mq(md) {
        border: none;
        font-size: 1.7rem;
        padding: 12px 0 3px;
      }
    }
  }

  &__tableValue {
    font-size: 1.6rem;
    color: #000;
    font-weight: normal;
    border-bottom: 1px solid #ddd;
    padding: 25px 0 10px;
    letter-spacing: 0.02rem;
    @include mq(md) {
      font-size: 1.4rem;
      padding: 10px 0 5px;
      line-height: 1.65;
    }

    &.-welfare {
      @include mq(md) {
        display: block;
        padding-top: 0;
        padding-bottom: 17px;
      }
    }

    > small {
      font-size: 1.4rem;
      @include mq(md) {
        font-size: 1.2rem;
        display: inline-block;
        line-height: 1.6;
      }
    }
  }

  /*-----------------------
  福利厚生
  -----------------------*/

  &__allowance {
    margin: 0;
    display: flex;
    @include mq(md) {
      display: block;
      padding: 5px 0;
    }
  }

  &__allowanceName {
    display: inline-block;
    width: 15%;
    min-width: 140px;
    font-weight: bold;
    text-align: right;
    margin: 0;
    @include mq(md) {
      text-align: left;
    }
  }

  &__allowanceText {
    display: block;
    width: 83%;
    margin: 0 0 0 10px;
    @include mq(md) {
      width: 100%;
      margin: 0;
    }
  }
  /*-----------------------
  研修制度
  -----------------------*/

  &__trainingContainer {
    display: flex;
    height: 141px;
    margin-bottom: 15px;

    &:nth-of-type(3) {
      height: auto;
    }
    @include mq(md) {
      height: auto;
      margin-bottom: 10px;
    }
  }

  &__trainingDate {
    flex-basis: 18%;
    background: #f2f2f2;
    font-size: 2rem;
    @include mq(md) {
      flex-basis: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > h3 {
      margin: 0;
      height: 141px;
      line-height: 141px;
      text-align: center;
      font-size: 2rem;
      min-width: 190px;
      &.-large {
        height: 546px;
        line-height: 546px;
        @include mq(md) {
          height: auto;
          line-height: initial;
        }
      }
      @include mq(md) {
        min-width: initial;
        font-size: 1.5rem;
        height: auto;
        line-height: initial;
      }

      > strong {
        position: relative;
        font-size: 4rem;
        line-height: 1;
        top: 1px;
        @include mq(md) {
          font-size: 2.8rem;
        }
      }
    }
  }

  &__trainingWrapper {
    flex-basis: 82%;
    @include mq(md) {
      flex-basis: 70%;
    }
  }

  &__trainingContent {
    flex-basis: 82%;
    display: flex;
    border: 6px solid #f2f2f2;
    min-width: 550px;
    &.-continuity {
      border-bottom: none;
    }
    @include mq(md) {
      flex-basis: 70%;
      display: block;
      min-width: initial;
      width: 100%;
      padding: 13px 15px 12px 15px;
    }
  }

  &__trainingTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 190px;
    min-width: 160px;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: color(brand);
    // line-height: 129px;
    line-height: 1.4;
    padding: 0 15px;
    @include mq(md) {
      font-size: 1.4rem;
      text-align: center;
      width: initial;
      min-width: initial;
      line-height: initial;
      padding: 0;
      margin-bottom: 3px;
    }
  }

  &__trainingMenu {
    width: 100%;
    position: relative;
    padding: 0 0 0 45px;
    @include mq(md) {
      width: initial;
      padding: 0;
    }

    > p {
      display: flex;
      align-items: center;
      height: 129px;
      font-size: 1.6rem;
      font-family: $font-family-exception;
      white-space: unset;
      padding-right: 20px;
      @include mq(md) {
        font-size: 1.35rem;
        height: auto;
        line-height: 1.5;
        padding-right: 0;
        letter-spacing: -0.04rem;
      }
    }
  }

  &__text {
    font-size: 1.6rem;
    font-family: $font-family-exception;
    letter-spacing: -0.02rem;
    margin-bottom: 32px;
    @include mq(md) {
      font-size: 1.4rem;
      line-height: 1.6;
      margin-bottom: 22px;
    }
  }
}
