.p-pageHeader {
  position: relative;

  @include mq(md) {
    background: initial;
  }

  &.-peopleDetail {
    @include mq(md) {
      background: initial;
    }
  }

  &__inner {
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
  }

  &__kv {
    position: relative;
    display: block;
    z-index: 4;
    width: 100%;
    max-width: 1440px;
    margin-right: auto;
    opacity: 0;

    > picture {
      display: block;
    }

    &.-peopleDetail {
      max-width: 1360px;
      margin: 0 auto;
    }
  }

  &__bg {
    width: 100%;
    // height: 250px;
    height: 0px;
    position: absolute;
    bottom: 0px;
    @include mq(md) {
      display: none;
    }
  }

  &__img {
    width: 100%;
    overflow: hidden;

    > img {
      @include mq(md) {
        width: 100%;
      }
    }
  }

  /*-----------------------
  タイトルコンテナ
  -----------------------*/

  &__titleWrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  &__titleContainer {
    position: absolute;
    z-index: z(pageHeader_title);
    background-color: #fff;
    width: 420px;
    height: 210px;
    top: 60px;
    right: 0;
    opacity: 0;
    @include mq(md) {
      left: 0;
      top: 87%;
      width: 61%;
      min-width: 250px;
      max-width: 360px;
      height: auto;
      padding: 18px 26px 30px;
    }

    /* 代表メッセージ */
    &.-topMessage {
      top: 40px;
      width: 570px;
      height: 310px;
      @include mq(1200px, max) {
        width: 470px;
      }
      @include mq(md) {
        width: 79%;
        min-width: 270px;
        max-width: 355px;
        top: 72%;
        height: 180px;
        padding: 0;
      }
      @include mq(450px, max) {
        width: 270px;
      }
      @include mq(380px, max) {
        top: 67%;
        width: 270px;
      }
    }

    /* 社員紹介 */
    &.-people {
      @include mq(md) {
        top: 84%;
        width: 40%;
        min-width: 166px;
        padding: 26px 26px 33px;
      }
    }

    /* 社員紹介 詳細 */
    &.-peopleDetail {
      background-color: transparent;
      top: 0px;
      right: 0px;
      width: initial;
      height: 400px;
      padding: 183px 40px 0 0;
      opacity: 1;

      @include mq(1360px, max) {
        padding: 11% 2% 0 0;
      }

      @include mq(md) {
        padding: 0;
        position: relative;
        background: #fff;
        max-width: initial;
      }
    }

    /* 採用情報 */
    &.-employment {
      width: 340px;
      @include mq(md) {
        top: 84%;
        width: 40%;
        min-width: 165px;
        padding: 26px 26px 34px;
      }
    }

    /* 採用情報 */
    &.-faq {
      width: 370px;
      @include mq(md) {
        width: 71%;
        max-width: 250px;
        min-width: initial;
      }
    }

    /* 数字で見るCasa */
    &.-data {
      @include mq(md) {
        top: 84%;
        width: 59%;
        min-width: 250px;
        padding: 26px 26px 33px;
      }
    }
  }

  &__titleText {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 60px;
    width: 420px;

    @include mq(md) {
      top: initial;
      transform: initial;
      padding: 0;
    }

    /* 代表メッセージ */
    &.-topMessage {
      top: initial;
      transform: initial;
      padding: 69px;
      width: 570px;
      @include mq(1200px, max) {
        width: 470px;
      }
      @include mq(md) {
        width: 355px;
        padding: 26px 24px 26px 26px;
      }

      @include mq(450px, max) {
        width: 280px;
      }
    }

    /* 社員紹介詳細 */
    &.-peopleDetail {
      top: initial;
      transform: initial;
      padding: initial;
      width: 380px;

      &.-large {
        width: 450px;
        @include mq(md) {
          width: 340px;
        }
      }
      @include mq(md) {
        width: 340px;
        padding: 27px 27px 22px;
      }

      &[data-type='interview'] {
        @include mq(md) {
          padding: 20px 27px 22px;
        }
      }
    }

    /* faq */
    &.-faq {
      padding: 0 60px;
      width: 370px;
      @include mq(md) {
        padding: 0;
      }
    }
  }

  /*-----------------------
  メインタイトル
  -----------------------*/
  &__title {
    font-size: 3.6rem;
    color: #009b87;
    line-height: 1;
    @include mq(md) {
      font-size: 2.6rem;
      // letter-spacing: 0.15rem;
    }

    &.-about {
      @include mq(md) {
        letter-spacing: 0;
      }
    }

    &.-topMessage {
      font-size: 3.2rem;
      letter-spacing: 0.03rem;
      line-height: 1.5;
      color: #000;
      @include mq(md) {
        font-size: 2.2rem;
        margin-bottom: 2px;
        min-height: 66px;
      }
    }

    &.-peopleDetail {
      line-height: 1.5;
      color: #000;
      margin-bottom: 12px;
      @include mq(md) {
        line-height: 1.4;
      }

      &[data-type='interview'] {
        position: relative;
        padding-top: 40px;
        &:before {
          content: '座談会';
          position: absolute;
          top: 10px;
          font-size: 1.4rem;
          background: #0022ee;
          color: #fff;
          padding: 4px 8px;
        }
      }
    }
  }

  &__title-casa {
    position: relative;
    font-size: 4.5rem;
    right: -5px;
    font-weight: 500;

    @include mq(md) {
      font-size: 3.2rem;
      right: 0;
      line-height: 1;
    }
  }

  /*-----------------------
  サブタイトル
  -----------------------*/

  &__title-sub {
    display: block;
    font-size: 1.6rem;
    color: #757575;
    line-height: 1;
    font-family: $font-family-serif;
    margin-bottom: 7px;
    @include mq(md) {
      font-size: 1rem;
      margin-bottom: 4px;
    }

    &.-topMessage {
      margin-bottom: 7px;
      @include mq(md) {
        margin-bottom: 5px;
      }
    }

    &.-peopleDetail {
      margin-bottom: 6px;
      color: inherit;
      @include mq(md) {
        font-size: 1.3rem;
        margin-bottom: 0;
      }
    }

    &.-jp {
      font-size: 2rem;
      font-style: normal;
      font-family: inherit;
      font-weight: bold;
      margin-bottom: 10px;
      color: color(brand);
      @include mq(md) {
        font-size: 1.6rem;
        margin-bottom: 5px;
      }
    }
  }

  &__name {
    font-size: 1.6rem;
    font-weight: bold;
    color: #757575;
    @include mq(md) {
      font-size: 1.35rem;
    }
  }
  /*-----------------------
  社員紹介詳細
  -----------------------*/
  &__personYear,
  &__personName {
    font-size: 1.6rem;
    font-weight: bold;
    color: inherit;
    margin-bottom: 10px;
    padding-left: 3px;
    line-height: 1;
    font-family: $font-family-exception;
    @include mq(md) {
      padding-left: 0;
      font-size: 1.4rem;
      margin-bottom: 7px;
    }
  }
}

// IE11
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  .p-pageHeader__title {
    font-size: 3rem;
  }
  .p-pageHeader__titleContainer {
    width: 430px;
  }
}
