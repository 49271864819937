.p-about {
  margin-top: -250px;
  @include mq(md) {
    margin-top: 0;
  }
  &__inner {
    padding: 161px 115px 120px 115px;
    @include mq(md) {
      padding: 70px 27px 90px 27px;
      margin-right: calc(((100vw - 100%) / 2) * -1);
    }
  }

  /*-----------------------
  メッセージ
  -----------------------*/

  &__message {
    display: flex;
    max-width: 1440px;
    margin: 0 auto;
    @include mq(md) {
      display: block;
    }
  }

  &__titleContainer {
    width: 45%;
    padding-top: 10px;
    @include mq(md) {
      width: 100%;
    }

    &.-message {
      width: 40%;
      @include mq(md) {
        width: 100%;
      }
    }
  }

  &__messageBody {
    padding-top: 0px;
    @include mq(md) {
      padding-top: 24px;
    }
  }

  &__text {
    font-size: 2rem;
    line-height: 2;
    margin-bottom: 20px;
    font-family: $font-family-exception;
    @include mq(md) {
      font-size: 1.5rem;
      letter-spacing: 0.03rem;
      margin-bottom: 10px;
    }
  }

  /*-----------------------
  事業紹介
  -----------------------*/
  &__business {
    display: flex;
    padding-top: 177px;
    max-width: 1440px;
    margin: 0 auto;
    @include mq(md) {
      display: block;
      padding-top: 53px;
    }
  }

  &__businessBody {
    position: relative;
  }

  &__title-description {
    font-size: 1.8rem;
    margin-top: 38px;
    line-height: 2;
    font-family: $font-family-exception;
    @include mq(md) {
      font-size: 1.5rem;
      margin-top: 24px;
      letter-spacing: 0.05rem;
    }
  }

  &__btn {
    margin-top: 45px;
    @include mq(md) {
      margin-top: 20px;
      text-align: right;
    }
  }

  &__img {
    position: relative;
    z-index: z(aboutImg);
    bottom: 40px;
    left: 55px;
    display: block;
    @include mq(md) {
      left: initial;
      bottom: initial;
      margin-top: 35px;
    }

    > img {
      width: 100%;
    }
  }

  &__imgBg {
    position: absolute;
    z-index: z(aboutImgBg);
    right: -97px;
    bottom: -5px;
    width: 69%;
    height: 73%;
    @include mq(1150px, max) {
      bottom: 40px;
    }
    @include mq(md) {
      right: -27px;
      bottom: -20px;
    }
  }

  /*-----------------------
  ナビゲーションエリア
  -----------------------*/
  &__navArea {
    background-color: color(base);
    padding: 160px 175px 165px;
    @include mq(md) {
      padding: 62px 22px 88px;
    }
  }

  &__navList {
    display: flex;
    justify-content: center;
    @include mq(md) {
      display: block;
    }
  }

  &__navItem {
    width: 50%;
    max-width: 540px;
    position: relative;
    background: #d8dee2;
    margin-right: 15px;
    @include mq(md) {
      width: 100%;
      max-width: initial;
      border: 5px solid #ebeff2;
    }

    &:last-of-type {
      margin-right: 0;
      @include mq(md) {
        border-top: none;
      }
    }

    > a {
      display: block;
      color: #6f787f;
      font-size: 2.2rem;
      font-weight: bold;
      padding: 45px 50px;
      @include hover();
      @include mq(md) {
        font-size: 1.7rem;
        padding: 26px 27px;
      }

      &:after {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
        background-size: 100%;
        display: inline-block;
        background-image: url('/assets/media/img/common/arw-navbtn.svg');
        top: 50%;
        transform: translateY(-50%);
        right: 9%;
        width: 36px;
        height: 36px;
        transition: transform 0.2s;
        @include mq(md) {
          width: 28px;
          height: 28px;
          right: 8%;
        }
      }
    }
  }
}
