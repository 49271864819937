.p-breadcrumb {
  position: relative;
  z-index: z(breadcrumb);
  transform: translateY(-250px);
  @include mq(md) {
    transform: translateY(0px);
  }

  &__inner {
    max-width: 1440px;
    margin: 0 auto;
  }

  &__list {
    display: flex;
    padding: 284px 59px 44px;
    @include mq(md) {
      padding: 77px 27px 26px;
    }

    &.-topMessage {
      @include mq(md) {
        padding: 127px 27px 26px;
      }
    }

    &.-peopleDetail {
      @include mq(md) {
        padding: 22px 27px 26px;
      }
    }
  }

  &__listItem {
    margin-right: 15px;
    @include mq(md) {
      margin-right: 10px;
    }

    > a {
      color: #fff;
      font-size: 1.3rem;
      font-family: '游ゴシック体', YuGothic, sans-serif;
      letter-spacing: 0.075rem;
      @include mq(md) {
        font-size: 1.1rem;
      }

      &:after {
        content: '';
        background-repeat: no-repeat;
        background-size: 100%;
        position: relative;
        display: inline-block;
        background-image: url('/assets/media/img/common/arw-breadcrumb.svg');
        margin-left: 17px;
        top: 0px;
        width: 6px;
        height: 9px;
        transition: transform 0.2s;

        @include mq(md) {
          margin-left: 9px;
          top: 2px;
          width: 5px;
        }
      }

      &:hover {
        text-decoration: underline;
      }
    }

    > span {
      color: #fff;
      font-size: 1.3rem;
      font-family: '游ゴシック体', YuGothic, sans-serif;
      letter-spacing: 0.075rem;
      @include mq(md) {
        font-size: 1.1rem;
      }
    }

    &:last-of-type {
      > a {
        &:after {
          display: none;
        }
      }
    }
  }
}
