// mixin
// ==========================================================================

// media query
// --------------------------------------------------------------------------
// @include mq(引数1, 引数2) {
//   [style]
// }
//
// 引数1: sm, md, lg, number（_variables.scssのBreakpoint参照）
// 引数2: '', min

// 1. マルチデバイスで共通のスタイル
// 2. 特に小さいデバイス向けの調整スタイル
// 3. スマートフォン向けのスタイル
// 4. タブレット、PC向けのスタイル
// 5. 1209px(1208px + 1px)以上向けのスタイル
//
// ```scss
// .example {
//   color: #000; // 1
//
//   @include mq(sm) {
//     content: 'sm'; // 2
//   }
//
//   @include mq(md) {
//     content: 'md'; // 3
//   }
//
//   @include mq(md, min) {
//     content: 'md min'; // 4
//   }
//
//   @include mq(1208px, min) {
//     content: 'number'; // 5
//   }
// }
// ```
@mixin mq($size, $width: max) {
  $result: 0;
  @if (type-of($size) == number) {
    $result: $size;
  } @else {
    @each $key, $value in $screen {
      @if ($key == $size) {
        $result: $value;
      }
    }
  }
  @if ($width == min) {
    $result: $result + 1;
  }
  @media (#{$width}-width: #{$result}) {
    @content;
  }
}

// safe area: for iPhoneX landscape mode
// --------------------------------------------------------------------------
// 1. for iOS11.1
// 2. for iOS11.2
@mixin safe-area {
  padding-right: constant(safe-area-inset-right); // 1
  padding-right: env(safe-area-inset-right); // 2
  padding-left: constant(safe-area-inset-left); // 1
  padding-left: env(safe-area-inset-left); // 2
}

@mixin negative-safe-area {
  margin-right: calc(constant(safe-area-inset-right) * -1); // 1
  margin-right: calc(env(safe-area-inset-right) * -1); // 2
  margin-left: calc(constant(safe-area-inset-left) * -1); // 1
  margin-left: calc(env(safe-area-inset-left) * -1); // 2
}

// visually hidden
// --------------------------------------------------------------------------
@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

// placeholder
// --------------------------------------------------------------------------
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    opacity: 1;
    @content;
  }

  &::-moz-placeholder {
    opacity: 1;
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &:placeholder-shown {
    @content;
  }
}

//////////////////////////////////////////
// hover
//////////////////////////////////////////

$opacity: 0.6;
@mixin hover {
  transition: opacity 0.2s ease-out;
  &:hover {
    opacity: $opacity;
  }
  @include mq(md) {
    transition: none;
    &:hover {
      opacity: 1;
    }
  }
}

//////////////////////////////////////////
// icon
//////////////////////////////////////////

@mixin icon {
  content: '';
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
}
