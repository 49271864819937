.p-menu {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  z-index: z(menu);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  transition: all 0.3s;

  &.-show {
    visibility: visible;
    opacity: 1;
  }

  &__bg {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url('/assets/media/img/common/menu-bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 49%;
    @include mq(md) {
      position: absolute;
    }
  }

  &__box {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    // top: 0;
    // right: 0;
    // bottom: 0;
    // left: 0;
    margin: auto;
    width: 1040px;
    height: 500px;
    @include mq(md) {
      width: 100%;
      height: 100%;
      padding: 30px 0;
    }
    @include mq(380px, max) {
      width: 100%;
      height: 100%;
      padding: 30px 0;
      // height: 95%;
    }
    @include mq(sm) {
      width: 100%;
      height: 100%;

      // height: 95%;
    }
  }

  &__list {
    padding: 0 40px;
    width: 1040px;
    left: 50%;
    font-family: $font-family-exception;
    display: grid;
    grid-template-columns: 28% 31% 17% 24%;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'area1 area4 area5 area6'
      'area2 area4 area5 area7'
      'area3 area4 area5 area8';

    @include mq(md) {
      position: relative;
      width: auto;
      display: block;
      left: initial;
      top: initial;
      transform: initial;
      padding: 0 38px 0;
    }

    @include mq(360px, max) {
      padding: 0 26px;
    }

    > li:nth-child(1) {
      grid-area: area1;
    }

    > li:nth-child(2) {
      grid-area: area2;
    }

    > li:nth-child(3) {
      grid-area: area3;
      &:after {
        content: '';
        position: relative;
        display: block;
        right: -1px;
        width: 100%;
        height: 152px;
        border-right: 1px solid #333;
        @include mq(md) {
          display: none;
        }
      }
      @include mq(md) {
        padding-bottom: 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid #333;
      }
      @include mq(360px, max) {
        padding-bottom: 3px;
        margin-bottom: 14px;
      }
    }

    > li:nth-child(4) {
      grid-area: area4;
      padding-left: 83px;
      @include mq(md) {
        padding-left: 0;
        width: 50%;
        display: inline-block;
        max-width: 250px;
      }
    }

    > li:nth-child(5) {
      grid-area: area5;
      @include mq(md) {
        position: absolute;
        width: 50%;
        right: 0;
        display: inline-block;
      }
    }

    > li:nth-child(6) {
      grid-area: area6;
      > a {
        position: relative;
        padding-right: 20px;
        @include mq(md) {
          letter-spacing: -0.08rem;
          white-space: pre;
        }
        &:before {
          content: '';
          background-repeat: no-repeat;
          background-size: 100%;
          position: absolute;
          display: inline-block;
          background-image: url('/assets/media/img/common/exlink-footer.svg');
          margin-left: 8px;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          width: 9px;
          height: 7px;
          transition: transform 0.2s;
        }
      }
      @include mq(md) {
        position: absolute;
        display: inline-block;
        width: 50%;
        left: 34px;
        bottom: -44px;
      }
      @include mq(sm) {
        position: relative;
        left: 15px;
        top: 0;
        margin-bottom: 10px;
        // bottom: -36px;
      }
    }
  }

  &__childList {
    padding-top: 24px;
    min-width: 180px;

    &.-employment {
      @include mq(md) {
        padding-right: 0;
      }
    }

    @include mq(md) {
      padding-top: 9px;
      min-width: 145px;
    }
  }

  &__listItem {
    height: 70px;
    @include mq(md) {
      height: auto;
    }

    &.-large {
      border-right: 1px solid #333;
      @include mq(md) {
        border: none;
      }
    }

    > a {
      position: relative;
      display: inline-block;
      color: #fff;
      font-size: 2.8rem;
      font-weight: bold;
      overflow: hidden;
      @include mq(md) {
        font-size: 2rem;
        letter-spacing: 0.1rem;
      }
      @include mq(360px, max) {
        font-size: 1.8rem;
        line-height: 1.6;
      }
      &:hover {
        &:after {
          transform: translate(0, 0);
        }
      }

      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #fff;
        transform: translate(-110%, 0);
        transition: transform cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
        content: '';
      }

      &.-middle {
        font-size: 2.3rem;
        @include mq(md) {
          font-size: 2rem;
          letter-spacing: 0;
        }
        @include mq(360px, max) {
          font-size: 1.7rem;
        }
      }
    }

    &.-child {
      height: 25px;
      margin-bottom: 15px;
      margin-right: 0;
      @include mq(md) {
        margin-bottom: 5px;
      }

      > a {
        position: relative;
        display: initial;
        font-size: 2rem;
        font-weight: normal;
        padding-left: 28px;
        @include mq(md) {
          font-size: 1.4rem;
          padding-left: 17px;
        }

        &:before {
          content: '';
          background-repeat: no-repeat;
          background-size: 100%;
          position: absolute;
          display: inline-block;
          background-image: url('/assets/media/img/common/arw-footer.svg');
          top: 50%;
          left: 0;
          width: 7px;
          height: 12px;
          transition: transform 0.2s;
          transform: translate(0px, -50%);
          @include mq(md) {
            top: 50%;
            transform: translate(0px, -50%);
            width: 6px;
            height: 10px;
          }
        }
        &:after {
          content: none;
        }

        &:hover {
          &:before {
            transform: translate(4px, -50%);
          }
        }
      }
    }
  }

  /*-----------------------
  ボタン
  -----------------------*/

  &__btnWrapper {
    width: 300px;
    position: absolute;
    margin: 0 auto;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    @include mq(md) {
      width: 210px;
      position: relative;
      top: 50px;
      left: 0;
      transform: translateX(0);
    }

    // @include mq(560px, max) {
    //   bottom: 8%;
    // }
    // @include mq(360px, max) {
    //   bottom: 5%;
    // }
  }
}
