.p-footer {
  position: relative;

  &__upper {
    background-color: color(brand);
  }

  &__inner {
    display: flex;
    max-width: 1230px;
    margin: 0 auto;
    padding: 142px 20px 254px 75px;
    @include mq(md) {
      display: block;
      padding: 43px 0 61px;
    }
  }

  &__logo {
    width: 200px;
    margin-right: 115px;
    background: url('/assets/media/img/common/logo-footer.svg') no-repeat center;
    @include mq(md) {
      margin-right: initial;
      height: 112px;
      margin: 0 auto;
      background-size: contain;
    }
  }

  /*-----------------------
  リスト
  -----------------------*/

  &__list {
    width: 850px;
    font-family: $font-family-exception;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'area1 area4 area5 area6'
      'area2 area4 area5 area7'
      'area3 area4 area5 area8';
    @include mq(md) {
      display: none;
    }

    > li:nth-child(1) {
      grid-area: area1;
    }

    > li:nth-child(2) {
      grid-area: area2;
    }

    > li:nth-child(3) {
      grid-area: area3;
    }

    > li:nth-child(4) {
      grid-area: area4;
    }

    > li:nth-child(5) {
      grid-area: area5;
    }

    > li:nth-child(6) {
      grid-area: area6;
    }

    > li:nth-child(7) {
      grid-area: area7;
    }

    > li:nth-child(8) {
      grid-area: area8;
    }
  }

  &__childList {
    padding-top: 12px;
  }

  &__listItem {
    height: 45px;

    > a {
      color: #fff;
      font-size: 1.8rem;
      font-weight: bold;
      // @include hover();

      &:hover {
        text-decoration: underline;
      }
    }

    &.-child {
      height: 25px;
      margin-bottom: 5px;
      margin-right: 0;

      > a {
        font-size: 1.4rem;
        font-weight: normal;

        &:before {
          content: '';
          background-repeat: no-repeat;
          background-size: 100%;
          position: relative;
          display: inline-block;
          background-image: url('/assets/media/img/common/arw-footer.svg');
          margin-right: 10px;
          top: -1px;
          width: 5px;
          height: 8px;
          transition: transform 0.2s;
        }

        &:hover {
          &:before {
            transform: translateX(3px);
          }
        }
      }
    }

    &.-corp {
      > a {
        &:after {
          content: '';
          background-repeat: no-repeat;
          background-size: 100%;
          position: relative;
          display: inline-block;
          background-image: url('/assets/media/img/common/exlink-footer.svg');
          margin-left: 8px;
          top: -4px;
          width: 9px;
          height: 7px;
          transition: transform 0.2s;
        }
      }
    }
  }

  /*-----------------------
  トップへ戻る
  -----------------------*/

  &__pagetop {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -30px);
    @include mq(md) {
      transform: translate(-50%, -22.5px);
    }
  }

  &__pagetopLink {
    background-color: white;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    color: #1f2833;
    line-height: 2.5;
    display: block;
    padding: 5px 10px;
    box-shadow: 0 5px 20px -8px rgba(0, 0, 0, 0.4);
    @include mq(md) {
      width: 45px;
      height: 45px;
    }
  }

  &__pagetopIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__lower {
    background-color: color(main);
  }

  /*-----------------------
  コピーライト
  -----------------------*/

  &__copyright {
    display: block;
    color: #556677;
    font-size: 1.2rem;
    text-align: center;
    padding: 70px 0;
    @include mq(md) {
      font-size: 1.15rem;
      padding: 40px 0;
    }
  }
}
