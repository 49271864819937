.p-dataSP {
  & section {
    overflow: hidden;
    &::before {
      content: '';
      background-image: url('/assets/media/img/common/triangle.svg');
      position: absolute;
      width: 20px;
      height: 20px;
      display: block;
    }
  }
  &__inner {
    & section {
      background: #fff;
      margin-bottom: 15px;
    }

    & h3 {
      margin: 0;

      > span {
        line-height: 1;
      }
    }

    & .p-dataSP__sectionTitle {
      font-weight: bold;
    }

    & .p-dataSP__subTitle {
      font-weight: bold;
    }

    & .p-dataSP__text {
      font-size: 1.5rem;
      font-family: $font-family-exception;
    }

    & .p-dataSP__num {
      color: color(brand);
      font-weight: bold;
      line-height: 1;
    }
  }

  /* --------------------------------

  セクション1

  ---------------------------------*/
  &__section1 {
    &__inner {
      padding: 25px;
    }
    &__wrapper1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      &__inner {
        width: 50%;
      }

      & .p-dataSP__sectionTitle {
        font-size: 2.2rem;
        @include mq(374px, max) {
          font-size: 1.8rem;
        }
      }

      & .p-dataSP__num {
        font-size: 4rem;
        @include mq(374px, max) {
          font-size: 3rem;
        }

        > span {
          font-size: 7rem;
          line-height: 0.7;
          @include mq(374px, max) {
            font-size: 6rem;
          }
        }
      }

      > .p-dataSP__picture {
        width: 40%;
        max-width: 102px;
      }
    }
  }

  /* --------------------------------

  セクション2

  ---------------------------------*/
  &__section2 {
    &__inner {
      padding: 25px;
      display: flex;
      justify-content: space-between;

      & .p-dataSP__num {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 10px;
        @include mq(sm) {
          font-size: 1.6rem;
        }
        > span {
          font-size: 3rem;
          line-height: 0.6;
          @include mq(sm) {
            font-size: 2.2rem;
          }
        }
      }
    }

    &__wrapper1 {
      width: 47%;
      > .p-data__picture {
        margin-top: 10px;
        margin-bottom: 30px;
      }
      > .p-dataSP__sectionTitle {
        font-size: 1.6rem;
      }
    }
    &__wrapper2 {
      width: 47%;
      & .p-dataSP__sectionTitle {
        font-size: 1.6rem;
      }

      &__inner1 {
        margin-bottom: 20px;
      }
    }
  }

  /* --------------------------------

  セクション3

  ---------------------------------*/

  &__section3 {
    &__inner {
      padding: 25px;
    }
    &__wrapper1 {
      display: flex;
      margin-bottom: 15px;
      @include mq(sm) {
        margin-bottom: 10px;
      }

      > .p-dataSP__picture {
        width: 32%;
        margin-right: 20px;
        @include mq(sm) {
          margin-right: 10px;
        }
      }

      &__inner {
        > .p-dataSP__sectionTitle {
          font-size: 1.8rem;
          padding-bottom: 20px;
          @include mq(sm) {
            padding-bottom: 10px;
          }
        }

        & .p-dataSP__section3__num {
          display: flex;

          > .p-dataSP__num {
            font-size: 3rem;
            font-weight: bold;

            &[data-type='coron'] {
              margin: 0 5px;
            }

            > span {
              position: relative;
              font-size: 4rem;
              line-height: 0.7;
              @include mq(sm) {
                font-size: 3rem;
              }

              &[data-type='men'] {
                &:before {
                  position: absolute;
                  content: '男';
                  font-size: 1.2rem;
                  left: 50%;
                  transform: translateX(-50%);
                  top: 0px;
                }
              }
              &[data-type='women'] {
                &:before {
                  position: absolute;
                  content: '女';
                  font-size: 1.2rem;
                  left: 50%;
                  transform: translateX(-50%);
                  top: 0px;
                }
              }
            }
          }
        }
      }
    }
  }

  /* --------------------------------

  セクション4

  ---------------------------------*/
  &__section4 {
    &__inner {
      padding: 25px;
    }
    &__wrapper1 {
      display: flex;
      margin-bottom: 10px;

      & .p-dataSP__sectionTitle {
        font-size: 1.8rem;
      }

      & .p-dataSP__num {
        font-size: 3rem;
        margin-bottom: 10px;
        @include mq(sm) {
          font-size: 2rem;
        }

        &.-dispatch {
          font-size: 1.6rem;
        }

        > span {
          font-size: 5rem;
          line-height: 0.7;
          @include mq(sm) {
            font-size: 4rem;
          }
        }
      }

      &__inner {
        width: 50%;
      }

      > .p-dataSP__picture {
        width: 48%;
      }
    }
  }

  /* --------------------------------

  セクション5

  ---------------------------------*/
  &__section5 {
    &__inner {
      padding: 25px;
    }
    &__wrapper1 {
      display: flex;
      margin-bottom: 15px;

      & .p-dataSP__sectionTitle {
        font-size: 1.8rem;
        @include mq(sm) {
          font-size: 1.4rem;
        }
      }

      & .p-dataSP__num {
        font-size: 4rem;
        line-height: 0.5;

        &.-dispatch {
          font-size: 1.6rem;
        }

        > span {
          font-size: 5.6rem;
          line-height: 0.7;
        }
      }

      &__inner {
        width: 50%;
        padding-top: 20px;
      }

      > .p-dataSP__picture {
        width: 45%;
        margin-right: 20px;
      }
    }
  }

  /* --------------------------------

  セクション6

  ---------------------------------*/
  &__section6 {
    &__inner {
      padding: 25px;
    }
    &__wrapper1 {
      display: flex;
      margin-bottom: 15px;

      &__inner {
        padding-left: 20px;
      }

      & .p-dataSP__sectionTitle {
        font-size: 1.8rem;
      }

      & .p-dataSP__num {
        font-size: 4rem;
        line-height: 0.5;

        &.-dispatch {
          font-size: 1.6rem;
        }

        > span {
          font-size: 5.6rem;
          line-height: 0.7;
        }
      }

      &__inner {
        width: 50%;
        padding-top: 20px;
      }

      > .p-dataSP__picture {
        width: 45%;
      }
    }
  }

  /* --------------------------------

  セクション7

  ---------------------------------*/
  &__section7 {
    &__inner {
      padding: 25px;
    }
    &__wrapper1 {
      display: block;
      margin-bottom: 15px;

      > .p-data__sectionTitle {
        margin: 0 0 10px 0;
      }

      > .p-data__text {
        flex-basis: 945px;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__item {
      width: 30%;
      max-width: 130px;
      margin-bottom: 20px;

      &:nth-of-type(4),
      &:nth-of-type(5) {
        margin-bottom: 0;
      }

      & + & {
        margin-left: 10px;
      }

      > .p-data__picture {
        width: 88%;
        margin: 0 auto 15px;
      }

      > .p-dataSP__subTitle {
        text-align: center;
        font-size: 1.4rem;
        font-weight: bold;
        margin-top: 10px;
      }

      > .p-dataSP__num {
        text-align: center;
        font-size: 2rem;

        > span {
          font-size: 3.2rem;
          line-height: 0.8;
        }
      }
    }
  }

  /* --------------------------------

  セクション8

  ---------------------------------*/
  &__section8 {
    &__inner {
      padding: 25px;
    }
    &__wrapper2 {
      display: block;

      > .p-data__sectionTitle {
        margin: 0 0 10px 0;
      }

      > .p-data__text {
        flex-basis: 945px;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__item {
      width: 40%;
      max-width: 130px;
      margin-bottom: 20px;

      & + & {
        margin-left: 10px;
      }

      > .p-data__picture {
        width: 88%;
        margin: 0 auto 15px;
      }

      > .p-dataSP__subTitle {
        text-align: center;
        font-size: 1.4rem;
        font-weight: bold;
        margin-top: 10px;
      }

      > .p-dataSP__num {
        text-align: center;
        font-size: 2rem;

        > span {
          font-size: 3.2rem;
          line-height: 0.8;
        }
      }
    }
  }

  /* --------------------------------

  セクション9

  ---------------------------------*/

  &__section9 {
    &__inner {
      padding: 25px;
    }
    &__wrapper1 {
      display: flex;
      justify-content: space-between;

      > .p-dataSP__picture {
        width: 55%;
        margin-top: 40px;
        @include mq(sm) {
          width: 42%;
        }
      }

      & .p-dataSP__sectionTitle {
        font-size: 1.6rem;
        margin-bottom: 15px;
      }

      & .p-data__sectionTitle {
        font-size: 1.5rem;
        margin-bottom: 5px;
      }

      & .p-data__num {
        > span {
          font-size: 5rem;
        }
      }
    }
    &__item {
      > dl {
        font-size: 1.6rem;
        font-family: $font-family-exception;
        line-height: 1;
        margin: 7px 0;
        > dt {
          display: inline-block;
        }

        > dd {
          width: auto;
          display: inline-block;
          margin-left: 15px;
          line-height: 1.2;
        }
      }

      &:nth-of-type(7) {
        > dl {
          > dt {
            position: relative;
            vertical-align: top;
          }
        }
      }
    }
  }

  /* --------------------------------

  セクション10

  ---------------------------------*/
  &__section10 {
    &__inner {
      padding: 25px;
    }
    &__wrapper2 {
      display: block;

      > .p-data__sectionTitle {
        margin: 0 0 10px 0;
      }

      > .p-data__text {
        flex-basis: 945px;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__item {
      width: 40%;
      max-width: 130px;
      margin-bottom: 20px;

      & + & {
        margin-left: 10px;
      }

      > .p-data__picture {
        width: 88%;
        margin: 0 auto 15px;
      }

      > .p-dataSP__subTitle {
        text-align: center;
        font-size: 1.4rem;
        font-weight: bold;
        margin-top: 10px;
      }

      > .p-dataSP__num {
        text-align: center;
        font-size: 2rem;

        > span {
          font-size: 3.2rem;
          line-height: 0.8;
        }
      }
    }
  }

  /* --------------------------------

  セクション11

  ---------------------------------*/

  &__section11 {
    &__inner {
      position: relative;
      padding: 25px;
    }

    &__wrapper1 {
      display: flex;
      justify-content: space-between;

      > .p-dataSP__section11__wrapper1_1 {
        flex-basis: 60%;

        > .p-dataSP__sectionTitle {
          font-size: 1.8rem;
          font-weight: bold;

          &:first-of-type {
            margin-bottom: 15px;
            @include mq(sm) {
              margin-bottom: 5px;
            }
          }
        }

        > .p-dataSP__subTitle {
          font-size: 2.5rem;
          display: inline-block;
          margin-right: 5px;
          font-weight: bold;
          line-height: 0.8;
          @include mq(sm) {
            font-size: 2.2rem;
          }
        }

        > .p-dataSP__num {
          font-size: 3rem;
          font-weight: bold;
          display: inline-block;
          line-height: 0.8;

          > span {
            font-weight: bold;
            font-size: 5.6rem;
            line-height: 0.3;
            @include mq(sm) {
              font-size: 4.2rem;
            }
          }
        }
      }
      > .p-dataSP__section11__wrapper1_2 {
        flex-basis: 40%;
        max-width: 140px;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
    }

    &__item {
      width: 120px;
      @include mq(sm) {
        width: 100px;
      }
      > dl {
        font-size: 1.6rem;
        font-family: $font-family-exception;
        margin: 7px 0;
        line-height: 1;
        white-space: nowrap;
        @include mq(sm) {
          font-size: 1.5rem;
        }
        > dt {
          display: inline-block;
        }

        > dd {
          display: inline-block;
          margin-left: 5px;
          @include mq(sm) {
            margin-left: 0;
          }
        }
      }
    }
  }

  /* --------------------------------

  セクション12

  ---------------------------------*/

  &__section12 {
    &__inner {
      display: flex;
      justify-content: space-between;
      position: relative;
      padding: 25px;
      @include mq(sm) {
        padding: 25px 10px 25px 25px;
      }
    }

    &__wrapper1 {
      min-width: initial;
      width: 60%;
      margin-right: 0;
      @include mq(sm) {
        width: 90%;
      }

      > .p-dataSP__sectionTitle {
        font-size: 1.8rem;
        margin-bottom: 5px;
      }
    }

    &__item {
      > .p-dataSP__subTitle {
        display: inline-block;
        font-size: 1.8rem;
        margin-right: 5px;
      }

      > .p-dataSP__num {
        display: inline-block;
        font-size: 2.4rem;
        margin-bottom: 8px;

        > span {
          font-size: 3.2rem;
        }
      }
    }

    &__wrapper2 {
      margin-top: auto;
      flex-basis: initial;
      margin: auto;

      > .p-data__picture {
        max-width: 160px;
      }
    }
  }

  /* --------------------------------

  セクション13

  ---------------------------------*/

  &__section13 {
    &__inner {
      position: relative;
      padding: 25px;
    }

    &__wrapper1 {
      display: block;
      margin-bottom: 20px;

      > .p-dataSP__sectionTitle {
        margin-bottom: 10px;
        font-size: 1.6rem;
      }

      > .p-dataSP__text {
        flex-basis: 790px;
      }
    }

    &__wrapper2 {
      & .p-dataSP__sectionTitle {
        font-size: 1.6rem;
      }
      & .p-dataSP__subTitle {
        font-size: 1.8rem;
      }
      & .p-dataSP__num {
        font-size: 3.6rem;
        margin-bottom: 10px;
        > span {
          font-size: 4.6rem;
        }
      }

      & .p-dataSP__section13__wrapper2-2 {
        position: absolute;
        top: 170px;
        right: 2%;
        width: 43%;
        @include mq(sm) {
          top: 190px;
          width: 40%;
        }
      }
    }

    &__item {
      > dl {
        font-size: 1.6rem;
        font-family: $font-family-exception;
        margin: 0;
        @include mq(sm) {
          font-size: 1.5rem;
        }
        > dt {
          display: inline;
        }
        > dd {
          width: auto;
          display: inline-block;
          margin-left: 5px;
          vertical-align: top;

          > .break {
            @include mq(425px, max) {
              display: none;
            }
          }
        }
      }
    }
  }
}
