// 関数
// ==========================================================================

// z-index
// --------------------------------------------------------------------------
// ```scss
// z-index: z(key);
// ```
@function z($name, $childname: 0) {
  $getkey: map-get($z-map, $name);
  $counter: 1;
  $is-index: 0;
  @if $childname != 0 {
    @return index($getkey, $childname);
  }

  @each $map-key, $map-value in $z-map {
    @if $map-key == $name {
      $is-index: $counter;
    }
    $counter: $counter + 1;
  }

  @return $is-index;
}

// color
// --------------------------------------------------------------------------
// ```scss
// color: color(key);
// ```
@function color($name) {
  @return map-get($color, $name);
}
