.p-people {
  margin-top: -250px;
  @include mq(md) {
    margin-top: 0;
  }
  &__inner {
    padding: 130px 0 163px 0;
    margin-bottom: 205px;
    @include mq(md) {
      padding: 50px 0 110px 0;
      margin-bottom: 0;
    }
  }

  &__content {
    border-bottom: 1px solid #eee;
    @include mq(md) {
      padding-bottom: 36px;
    }
  }

  &__contentInner {
    display: flex;
    justify-content: space-between;
    flex-flow: row-reverse;
    padding: 40px 0 40px 115px;
    max-width: 1440px;
    transition: opacity 1.5s;

    &.-hidden {
      visibility: hidden;
      opacity: 0;
    }

    &.-show {
      visibility: visible;
      opacity: 1;
    }

    @include mq(1440px, min) {
      margin: 0 auto;
    }
    @include mq(md) {
      display: block;
      padding: 33px 0 0;
    }
  }

  /*-----------------------
  メタ情報
  -----------------------*/

  &__container-meta {
    flex-basis: 40%;
    padding-right: 20px;
    @include mq(md) {
      padding: 18px 18px 0 54px;
    }
  }

  &__department {
    display: inline-block;
    font-size: 1.8rem;
    color: color(brand);
    margin-bottom: 20px;
    @include mq(md) {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: color(brand);
    }
  }

  &__num {
    font-size: 1.7rem;
    font-family: $font-family-serif;
    @include mq(md) {
      font-size: 1.3rem;
    }
  }

  &__title {
    font-size: 4rem;
    line-height: 1.38;
    letter-spacing: 0.02rem;
    margin-bottom: 8px;
    @include mq(md) {
      font-size: 2.7rem;
      line-height: 1.2;
      margin-bottom: 13px;
    }
  }

  &__year {
    padding-left: 5px;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.04rem;
    @include mq(md) {
      padding-left: 0;
      font-size: 1.5rem;
      line-height: 1;
    }
  }

  &__name {
    padding-left: 5px;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.04rem;
    @include mq(md) {
      padding-left: 0;
      font-size: 1.5rem;
    }
  }

  &__btn {
    margin-top: 30px;
    @include mq(md) {
      margin-top: 15px;
    }
  }

  /*-----------------------
  画像
  -----------------------*/
  &__container-img {
    flex-basis: 60%;
    display: flex;
    align-items: center;
    @include mq(md) {
      width: 100%;
      padding-left: 27px;
    }
  }

  &__img {
    width: 100%;
    @include mq(md) {
      height: initial;
    }

    &[data-type='interview'] {
      &:before {
        content: '座談会';
        font-size: 1.6rem;
        position: absolute;
        color: #fff;
        background: #0022ee;
        padding: 1px 9px;
        @include mq(md) {
          font-size: 1.4rem;
        }
      }
    }
  }
}
