.p-entry {
  margin-top: -250px;
  @include mq(md) {
    margin-top: 0;
  }
  &__inner {
    padding: 160px 115px 80px;
    margin-bottom: 200px;
    @include mq(md) {
      padding: 70px 27px 10px 27px;
      margin-bottom: 60px;
    }
  }

  &__section {
    max-width: 1150px;
    margin: 0 auto 70px;
    @include mq(md) {
      margin-bottom: 40px;
    }
  }

  &__sectionTitle {
    font-size: 3rem;
    color: color(brand);
    margin-bottom: 17px;
    @include mq(md) {
      font-size: 2rem;
      margin-bottom: 10px;
    }
  }

  &__contentWrapper {
    padding-left: 55px;
    @include mq(md) {
      padding-left: 20px;
    }
  }

  &__list {
    list-style-type: disc;
    margin-bottom: 17px;
    margin-left: 23px;
  }

  &__item {
    font-size: 1.8rem;
    font-weight: bold;
    @include mq(md) {
      font-size: 1.5rem;
    }
  }

  &__text {
    font-size: 1.6rem;
    margin-bottom: 35px;
    font-family: $font-family-exception;
    @include mq(md) {
      font-size: 1.5rem;
    }
  }

  &__link {
    text-decoration: underline;
    @include hover();

    &.-file {
      &:after {
        content: '';
        background-repeat: no-repeat;
        background-size: 100%;
        position: relative;
        display: inline-block;
        background-image: url('/assets/media/img/common/icon-file.svg');
        margin-left: 13px;
        top: 2px;
        width: 16px;
        height: 20px;
      }
    }
  }

  &__address {
    margin: 60px 0 60px;
    border: 1px solid #ddd;
    max-width: 1030px;
    padding: 50px 58px 50px;

    > .p-entry__text {
      margin-bottom: 0;
      line-height: 1.9;
    }

    @include mq(md) {
      margin-top: 45px;
      padding: 25px 30px 25px;
    }
  }
}
